import  { UserGetAllResponse } from "../../models/from-api-response/user";

const isAdmin = (roles: string[] | null) => {

  if (!roles) {
    return false;
  }

  const adminRole = 'Admin';
  const rolesUpper = roles.map((item: string) => item.toUpperCase())
  if (rolesUpper.indexOf(adminRole.toUpperCase()) < 0) {
    return false;
  }

  return true;
}

const convertToUser = (user: UserGetAllResponse): UserGetAllResponse => {
  return {
    acceptanceDate: user.info.date_agreed_wih_terms_of_use ? new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short'}).format(new Date(user.info.date_agreed_wih_terms_of_use)) : '',
    isAdmin: isAdmin(user.roles),
    ...user
  };
};

export default convertToUser;
