import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";
import DefaultButton from "../../components/DefaultButton";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import Course from "../../models/course";
import {
  deleteCourse,
  getAllCourses,
  activateOrInactivateCourse as activateOrInactivateCourseService,
} from "../../services/courses";
import { CoursesContainer } from "./style";

const Courses: React.FC = () => {
  const history = useHistory();

  const [courses, setCourses] = useState([] as Course[]);

  const getCourses = async () => {
    const localCourses = await getAllCourses();
    if (localCourses && localCourses.length) {
      setCourses(localCourses);
    }
  };

  const editCourse = useCallback(
    (courseId: any) => {
      history.push(`edit-episode/${courseId}`);
    },
    [history]
  );

  const removeCourse = useCallback(async (courseId: any) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover esta temporada?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCourse(courseId);

          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Temporada excluida com sucesso!",
          });

          await getCourses();
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "Erro ao excluir temporada.",
            text: `Erro ao excluir essa temporada. ${
              e.response && e.response.status === 423
                ? "Esta temporada já está associado a uma série!"
                : e.message
            }`,
          });
        }
      }
    });
  }, []);

  const createCourse = async () => {
    history.push("/trails/create-episode");
  };

  const activateOrInactivateCourse = useCallback(
    async (courseId: any, activate: boolean) => {
      Swal.fire({
        title: "Confirmação",
        text: `Tem certeza que deseja ${
          activate ? "ativar" : "inativar"
        } esta temporada?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateCourseService(courseId, activate);
            getCourses();
          } catch (error) {
            Swal.fire({
              title: "Erro",
              text: `Erro ao ${activate ? "ativar" : "inativar"} episódio! ${
                error.response && error.response.status === 423
                  ? "Esta temporada já está associado a uma série!"
                  : error.message
              }`,
            });
          }
        }
      });
    },
    []
  );

  const coursesToBeShown = useMemo(() => {
    return courses && courses.length
      ? courses.map((course) => ({
          id: course.id,
          course_id: course.course_id,
          name: course.title,
          description: course.description,
          active: course.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <>
              <DefaultButton
                className='small info'
                onClick={() => editCourse(course.course_id)}
              >
                <BiEdit />
              </DefaultButton>{" "}
              <DefaultButton
                onClick={() =>
                  activateOrInactivateCourse(
                    course.course_id,
                    !course.is_active
                  )
                }
                className='small warning'
                title={(course.active ? "Inativar" : "Ativar") + "Temporada"}
              >
                {course.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>{" "}
              <DefaultButton
                onClick={() => removeCourse(course.course_id)}
                className='small danger'
                title='Excluir Temporada'
              >
                <BiTrash />
              </DefaultButton>
            </>
          ),
        }))
      : [];
  }, [courses, editCourse, removeCourse, activateOrInactivateCourse]);

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <CoursesContainer>
      <BreadCrumb
        crumbs={[
          <Link to='/profile'>Perfil</Link>,
          <span>Séries</span>,
          <span>Temporadas</span>,
        ]}
      />

      <DefaultPageTitle>Temporadas</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createCourse}>Criar Temporada</DefaultButton>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: "name",
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: "description",
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: "active",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={coursesToBeShown}
        emptyListMessage={"Não foram encontrados temporadas cadastradas!"}
      />
    </CoursesContainer>
  );
};

export default Courses;
