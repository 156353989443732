import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import { hideModal, showModal } from "../../helpers/modal";
import {
  ContentThumbnail,
  CreateAndEditAuthorContainer,
  ThumbnailUploadContainer,
} from "./style";

import CutImage from "../../components/CutImage";
import checkEmptyString from "../../helpers/check-empty-string";
import { createAuthor, getAuthor, updateAuthor, changeAuthorThumbnail as changeAuthorThumbnailService, deleteAuthor } from "../../services/authors";

interface CreateAndEditContentProps {
  authorId: string;
}

const CreateAndEditContent: React.FC = () => {
  const { authorId } = useParams<CreateAndEditContentProps>();

  const history = useHistory();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnail, setThumbnail] = useState<any>();

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error("Informe um nome valido para o tema.");
      }

      const createdAuthor = await createAuthor({
        name,
        description: description.trim().length ? description : undefined
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Tema criado com sucesso!",
        icon: "success",
      });

      goToAuthors();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao criar o tema. " + error.message,
        icon: "error",
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error("Informe um nome valido para o tema.");
      }

      await updateAuthor(authorId, {
        name,
        description: description || undefined
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Tema editado com sucesso!",
        icon: "success",
      });

      goToAuthors();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao editar o tema. " + error.message,
        icon: "error",
      });
    }
  };

  const goToAuthors = () => {
    history.push("/authors");
  };

  const getContent = useCallback(async () => {
    if (authorId) {
      const author = await getAuthor(authorId);
      if (author && Object.keys(author).length) {
        setName(author.name);
        setDescription(author.description);
      }
    }
  }, [authorId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (authorId) {
      return true;
    }

    return false;
  }, [authorId]);

  return (
    <CreateAndEditAuthorContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/authors">Tema</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Tema</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Tema
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">Nome</label>
          <DefaultInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description || ''}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToAuthors}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateContent(e) : createContent(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditAuthorContainer>
  );
};

export default CreateAndEditContent;
