import React, { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import { getAllUsers, getCountUsers, updateUserRole } from "../../services/users";

import Switch from 'react-switch';

import {
  Container,
  CardContainer,
  Card,
  PaginationContainer,
  Actions
} from "./style";
import Swal from "sweetalert2";
import Pagination from "../../components/Pagination";
import DefaultInput from "../../components/DefaultInput";
import { AiOutlineSearch } from "react-icons/ai";
import { FaDownload, FaFileCsv } from "react-icons/fa";
import { GetCountUsersResponse, UserGetAllResponse } from "../../models/from-api-response/user";

const Users: React.FC = () => {
  const [users, setUsers] = useState([] as UserGetAllResponse[]);
  const [allUsers, setAllUsers] = useState([] as UserGetAllResponse[]);
  const [countUser, setCountUser] = useState<GetCountUsersResponse>({ total_accept_conditions_users: 0, total_users: 0 });
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);

  const headers = [
    { label: "Nome", key: "name" },
    { label: "E-mail", key: "email" },
    {label: "Employee ID", key: "employee_id"},
    {label: "Login", key: "login"},
    {label: "Company", key: "company"},
    {label: "HS Area", key: "hs_area"},
    {label: "HR Subarea", key: "hr_subarea"},
    {label: "Grupo", key: "grupo"},
    {label: "SUbgrupo", key: "subgroup"},
    {label: "Job Title", key: "job_title"},
    {label: "BMU Name", key: "bmu_name"},
    {label: "Division Name", key: "division_name"},
    {label: "Status", key: "status"},
    {label: "Hire Date", key: "hire_date"},
    {label: "Exit Date", key: "exit_date"},
    { label: "Data de Aceite do Termo", key: "acceptanceDate" }
  ];

  const getUsers = useCallback(async (filters) => {

    if (search) {
      filters.search = search;
    }

    const allUsers = await getAllUsers(filters);
    if (allUsers.users) {
      setUsers(allUsers.users);
      setTotal(allUsers.count);
    }
  }, [search]);

  const handleGenerateAllUsersFile = async () => {
    const allUsers = await getAllUsers({});
    setAllUsers(allUsers.users);
  }

  const updatePermission = useCallback(async (user: UserGetAllResponse) => {
    try {
      const adminRole = 'Admin';
      let rolesToSave: string[] = [];

      if ((!user.roles || !user.roles.length) && !user.isAdmin) {
        rolesToSave = [adminRole];
      } else if (user.roles && !user.isAdmin) {
        rolesToSave = [adminRole];
      } else if (user.roles && user.isAdmin) {
        rolesToSave = [];
      }

      await updateUserRole(user.user_id, rolesToSave);

      Swal.fire({
        title: 'Sucesso',
        text: 'Permissão alterada com sucesso',
        icon: 'success',
      });

      setUsers(users.map(item => {
        if (item.user_id === user.user_id) {
          item.isAdmin = !user.isAdmin;
        }

        return item;
      }))

    } catch (err) {
      Swal.fire({
        title: 'Erro',
        text: 'Não foi alterar a permissão do usuário.',
        icon: 'error',
      });
    }
  }, [users]);


  const usersToBeShown = useMemo(() => {
    return users && users.length
      ? users.map((user) => ({
        name: user.name,
        email: user.email,
        date: user.acceptanceDate,
        employee_id: user.employee_id,
        login: user.login,
        company: user.company,
        hs_area: user.hs_area,
        hr_subarea: user.hr_subarea,
        grupo: user.grupo,
        subgroup: user.subgroup,
        job_title: user.job_title,
        bmu_name: user.bmu_name,
        division_name: user.division_name,
        status: user.status,
        hire_date: user.hire_date,
        exit_date: user.exit_date,
        is_admin: (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              gap: '5px',
            }}
            title={user.isAdmin ? 'Remover permissões' : 'Tornar admin'}
          >
            <Switch
              onChange={checked => updatePermission(user)}
              checked={user.isAdmin || false}
            />
          </div>
        ),
      }))
      : [];
  }, [updatePermission, users]);

  const handleGetUserSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (page === 1) {
      getUsers({ limit: 25, offset: ((page - 1) * 25) });
    } else {
      setPage(1);
    }
  }

  useEffect(() => {
    getUsers({ limit: 25, offset: ((page - 1) * 25) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    const getCountUsersData = async () => {
      const data = await getCountUsers();
      setCountUser(data);
    }
    getCountUsersData()
  }, [])

  return (
    <Container>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Usuários</span>]}
      />
      <DefaultPageTitle>Usuários</DefaultPageTitle>
      <Actions>
        <DefaultButton>
          <CSVLink
            filename={`alpargatas-usuarios-pagina-${page}.csv`}
            data={users}
            headers={headers}
            className="csv-download-button"
          >
            <FaFileCsv /> Exportar Seleção Atual
          </CSVLink>
        </DefaultButton>

        {allUsers.length ? (
          <DefaultButton>
            <CSVLink
              filename={`alpargatas-todos-usuarios.csv`}
              data={allUsers}
              headers={headers}
              className="csv-download-button"
            >
              <FaFileCsv /> Exportar Todos Usuários
            </CSVLink>
          </DefaultButton>
        ) : (
          <DefaultButton onClick={handleGenerateAllUsersFile} className="generate-button">
            <FaDownload /> Gerar - Todos Usuários
          </DefaultButton>)}


        <form
          onSubmit={handleGetUserSubmit}
        >
          <DefaultInput
            type="search"
            placeholder="Nome ou e-mail"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <DefaultButton type="submit">
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>

      </Actions>
      <CardContainer>
        <Card>
          <p>Total de Usuários</p>
          <h2>{countUser?.total_users}</h2>
        </Card>
        <Card>
          <p>Total de Aceites</p>
          <h2>{countUser?.total_accept_conditions_users}</h2>
        </Card>
        <Card>
          <p>Percentual de Aceite</p>
          <h2>{Math.trunc(countUser.total_accept_conditions_users * 100 / countUser.total_users)}%</h2>
        </Card>
      </CardContainer>
      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: "name",
          },
          {
            headerLabel: <span>E-mail</span>,
            propName: "email",
          },
          {
            headerLabel: <span>Área</span>,
            propName: "hs_area",
          },
          {
            headerLabel: <span>Data de Aceite do Termo</span>,
            propName: "date",
          },
          {
            headerLabel: <span>Admin</span>,
            propName: 'is_admin',
          },
        ]}
        items={usersToBeShown}
        emptyListMessage={"Não foram encontrados usuários!"}
      />

      <PaginationContainer>
        <Pagination totalPages={Math.ceil(total / 25)} setPage={page => setPage(page)} selected={page} />
      </PaginationContainer>

    </Container >
  );
};

export default Users;
