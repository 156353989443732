import convertToLesson from "../helpers/converters/convert-lesson";
import httpClient from "../http-client";
import LessonForCreate from "../models/for-create/lesson";
import ContentFromReponse from "../models/from-api-response/content";
import LessonFromReponse from "../models/from-api-response/lesson";
import Lesson from "../models/lesson";
import { uploadFile } from "./files";

const getLesson = async (contentId: string) => {
  const content = (
    await httpClient.get(`contents/${contentId}`)
  ).data;
  return content.content;
};

const getAllLessons = async (filters?: object) => {
  const contents = (await httpClient.get<LessonFromReponse[]>("contents",
    {
      params: {
        ...filters,
        is_library: false,
      }
    }
  ))
    .data;

  let convertedContents = [] as Lesson[];
  if (contents && contents.length) {
    convertedContents = contents.map(convertToLesson);
  }

  return convertedContents;
};

const createLesson = async (newLesson: LessonForCreate) => {
  const createLessonResponse = (
    await httpClient.post<ContentFromReponse>(`contents/`, newLesson)
  ).data;

  return createLessonResponse;
};

const updateLesson = async (
  contentId: string,
  newLesson: any
) => {
  await httpClient.put(
    `contents/${contentId}`,
    newLesson
  );
};

const deleteLesson = async (contentId: string) => {
  await httpClient.delete(
    `contents/${contentId}`
  );
};

const changeLessonThumbnail = async (
  contentId: string,
  formData: FormData
) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`contents/${contentId}/thumbnail`, {
    thumbnail: uploadedFile.reference,
  });
};

const activateOrInactivateLesson = async (
  contentId: string,
  activate: boolean
) => {
  await httpClient.put(
    `contents/${contentId}/active`,
    { is_active: activate }
  );
};

export {
  getAllLessons,
  deleteLesson,
  updateLesson,
  createLesson,
  changeLessonThumbnail,
  getLesson,
  activateOrInactivateLesson,
};
