import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;

`;

export const WelcomeMessage = styled.h2`
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 50px;
  margin-top: 63px;
`;

export const CardContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill,minmax(300px,350px));
`;

export const Card = styled.div`
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  padding: 16px;
  width: 100%;

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #32325D;
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #8898AA;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
`;

export const Graph = styled.div``;