import LessonFromReponse from "../../models/from-api-response/lesson";
import Lesson from "../../models/lesson";

const convertToLesson = (lesson: LessonFromReponse): Lesson => {
  return {
    content_id: lesson.content_id,
    duration: lesson.duration,
    info: lesson.info,
    is_active: lesson.is_active,
    position: lesson.position,
    title: lesson.title,
    description: lesson.description,
    reference: lesson.reference,
    thumbnail: lesson.thumbnail,
  };
};

export default convertToLesson;
