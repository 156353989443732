import { formatName } from './extraMaterial';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { ExtraMaterial } from '../pages/CreateAndEditContent/style';

const downloadMaterial = (material: typeof ExtraMaterial) => {
  axios
    .get(material.reference, {
      responseType: 'blob',
    })
    .then(res => {
      fileDownload(res.data, formatName(material.name));
    });
};

export { downloadMaterial }