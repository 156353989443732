import React, { useCallback, useEffect, useMemo, useState } from "react";
import DefaultButton from "../../../../components/DefaultButton";
import DefaultTable from "../../../../components/DefaultTable";
import Pagination from "../../../../components/Pagination";
import Lesson from "../../../../models/lesson";
import { getAllLessons } from "../../../../services/lessons";
import { SelectLessonContainer } from "./style";

interface SelectLessonProps {
  selectedLessons: Lesson[];
  onApplySelection: (selectedLessons: Lesson[]) => void;
}

const SelectLesson: React.FC<SelectLessonProps> = ({
  selectedLessons,
  onApplySelection,
}) => {
  const [allLessons, setAllLessons] = useState([] as Lesson[]);
  const [checkedLessons, setCheckedLessons] = useState(selectedLessons);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const lessonsPerPage = 10;
  const filter = { order_by: 'DESC' };

  const getLessons = async () => {
    const localLessons = await getAllLessons(filter);
    if (localLessons && localLessons.length) {
      setTotalPages(Math.ceil(localLessons.length / lessonsPerPage));
    }
  };

  const handleCheckLesson = useCallback(
    (event: React.FormEvent<HTMLInputElement>, lesson) => {
      if (event.currentTarget.checked) {
        setCheckedLessons([...checkedLessons, lesson]);
      } else {
        const filteredLessons = checkedLessons.filter(selectedLesson => selectedLesson.content_id !== lesson.content_id)
        setCheckedLessons(filteredLessons);
      }
    },
    [checkedLessons]
  );

  const applySelection = () => {
    onApplySelection(checkedLessons);
  };

  const lessonsToBeShown = useMemo(() => {
    return allLessons.map((lesson) => {
      const shouldBeChecked = selectedLessons
        .map((checkedLesson) => checkedLesson.content_id)
        .includes(lesson.content_id);

      return {
        thumbnail: (
          <img
            src={lesson.thumbnail}
            style={{ width: "300px" }}
            alt="thumbnail"
          />
        ),
        title: lesson.title,
        description: lesson.description,
        check: (
          <>
            <input
              type="checkbox"
              value={lesson.content_id}
              defaultChecked={shouldBeChecked}
              onChange={(e) => handleCheckLesson(e, lesson)}
            />
          </>
        ),
      };
    });
  }, [allLessons, checkedLessons, handleCheckLesson]);

  useEffect(() => {
    getLessons();
  }, []);

  useEffect(() => {
    (async () => {
      setAllLessons([]);

      const currentLessons = await getAllLessons({
        ...filter,
        limit: lessonsPerPage,
        offset: page > 1 ? (page - 1) * lessonsPerPage : undefined,
      });

      if (currentLessons && currentLessons.length) {
        setAllLessons(currentLessons);
      }
    })();
  }, [page]);

  return (
    <SelectLessonContainer>
      <DefaultTable
        headersConfig={[
          { headerLabel: <span>Thumbnail</span>, propName: "thumbnail" },
          { headerLabel: <span>Título</span>, propName: "title" },
          { headerLabel: <span>Descrição</span>, propName: "description" },
          { headerLabel: <span>Selecionar</span>, propName: "check" },
        ]}
        items={lessonsToBeShown}
      />
      {totalPages > 1 && (
        <Pagination totalPages={totalPages} setPage={setPage} />
      )}
      <DefaultButton onClick={applySelection}>Aplicar Seleção</DefaultButton>
    </SelectLessonContainer>
  );
};

export default SelectLesson;