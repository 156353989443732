import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import Swal from "sweetalert2";
import DefaultButton from "../DefaultButton";
import {
  ButtonGroupInline,
  Container,
  LanguageContainer,
  SubtitleUpload,
  ChosenFile
} from "./style";
import { hideModal } from "../../helpers/modal";
import Select from "react-select";
import { uploadSubtitles } from "../../services/contents";

interface SelectSubtitlesProps {
  reference: any;
}

const SelectSubtitles: React.FC<SelectSubtitlesProps> = ({reference}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [language, setLanguage] = useState({} as {label: string, value: string});
  const [subtitleFileToUpload, setSubtitleFileToUpload] = useState<any>();

  const [isUploading, setIsUploading] = useState(false);

  const languages = [
    {value: 'pt_BR', label: 'Português'},
    {value: 'es_ES', label: 'Espanhol'},
    {value: 'en_US', label: 'Inglês'},
    {value: 'fr_FR', label: 'Francês'},
    {value: 'de_DE', label: 'Alemão'}
  ]

  const submitSubtitles = async () => {
    const formData = new FormData();
    formData.append("file", subtitleFileToUpload);
    formData.append("captionLanguage", language.value);

    const videoId = reference.split('/')[reference.split('/').length-1];

    try {
      await uploadSubtitles(videoId, formData);
      hideModal();
    } catch {
      Swal.fire({
        title: "Erro!",
        text: "Houve um problema durante o upload do arquivo!",
        timer: 4000,
      });
    }

  }

  useEffect(() => {
    setLanguage(languages[0])
  }, [])

  const changeSubtitles =  (event: any) => {
    if (event.target.files[0]) {
      setSubtitleFileToUpload(event.target.files[0])
    }
  };

  const onUploadClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  }

  return (
    <Container>
      <SubtitleUpload onClick={onUploadClick}>
        <AiOutlineCloudUpload size={128} />
        {!isUploading && <p>Fazer Upload de Conteúdo</p>}
        {isUploading && <p>Aguarde o Upload do Conteúdo</p>}
        <input
          ref={fileInput}
          style={{ display: "none" }}
          accept=".srt"
          type="file"
          onChange={changeSubtitles}
        />
      </SubtitleUpload>
        <ChosenFile>
        {
          subtitleFileToUpload ? (
              <p>Legenda selecionada: {subtitleFileToUpload.name}</p>
          ) :
          (
            <p>Nenhuma legenda selecionada.</p>
          )
        }
        </ChosenFile>

      <LanguageContainer>
        <Select 
          defaultValue={languages[0]}
          options={languages} 
          value={language}
          onChange={(option) => option && setLanguage(option)}
        />
      </LanguageContainer>
      <ButtonGroupInline>
          <DefaultButton
            type="button"
            className="danger"
            onClick={hideModal}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={submitSubtitles}
            className="success"
          >
            Salvar
          </DefaultButton>
        </ButtonGroupInline>
    </Container>
  );
};

export default SelectSubtitles;
