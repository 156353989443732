import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultFilterForm from "../../components/DefaultFilterForm";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { EngagementContainer } from "./style";
import Select from "react-select";
import DefaultButton from "../../components/DefaultButton";
import {generateAccessUsersReport} from "../../services/reports";
import {getAllUsers} from "../../services/users";

const ReportAcessosUsuarios: React.FC = () => {
  const [users, setUsers] = useState(
      [] as { value: string; label: string }[]
  );

  const [selectedUser, setSelectedUser] = useState("");
  const [accessAtBegin, setAccessAtBegin] = useState("");
  const [accessAtEnd, setAccessAtEnd] = useState("");

  const generateReport = async (reportType: string) => {

    const queryStrings: any = {
      user_id: selectedUser,
      access_at_begin: accessAtBegin,
      access_at_end: accessAtEnd,
    };

    for (let prop of Object.keys(queryStrings)) {
      if (!queryStrings[prop]) {
        delete queryStrings[prop];
      }
    }

    await generateAccessUsersReport(reportType, queryStrings);
  };

  useEffect(() => {
    (async () => {
      const localUsers = await getAllUsers({});
      if (localUsers && localUsers.users.length) {
        setUsers(localUsers.users.map((u) => (
            { label: u.name, value: u.user_id })));
      }
    })();
  }, []);

  return (
      <EngagementContainer>
        <BreadCrumb
            crumbs={[
              <Link to="/home">Home</Link>,
              <span>Relatórios</span>,
              <span>Acessos - Usuários</span>,
            ]}
        />

        <DefaultPageTitle>Acessos - Usuários</DefaultPageTitle>

        <DefaultFilterForm>
        <span className="entire-row">
          <label htmlFor="usuarios">Usuários</label>
          <Select
              options={users}
              onChange={(option) => option && setSelectedUser(option.value)}
          />
        </span>

          <span>
          <label htmlFor="startAt">Início em</label>
          <input
              type="date"
              id="startAtGte"
              onChange={(e) => setAccessAtBegin(e.target.value)}
          />
        </span>

        <span>
            <label>até</label>
          <input
              type="date"
              id="startAtLte"
              onChange={(e) => setAccessAtEnd(e.target.value)}
          />
        </span>

          <span className="entire-row center">
          <DefaultButton
              onClick={() => generateReport("pdf")}
              type="button"
          >
            Gerar Relatório (PDF)
          </DefaultButton>{" "}
            <DefaultButton
                onClick={() => generateReport("csv")}
                type="button"
            >
            Gerar Relatório (CSV)
          </DefaultButton>
        </span>
        </DefaultFilterForm>
      </EngagementContainer>
  );
};

export default ReportAcessosUsuarios;
