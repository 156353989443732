import { useState } from "react";
import { useEffect } from "react";
import {
  AppContainer,
  AppContent,
  AppLeftContent,
  AppRightContent,
} from "./AppStyle";
import Routes from "./components/Routes";
import SideBar from "./components/SideBar";
import { getActualUser } from "./services/users";

function App() {

  const adminRole = 'Admin';
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (!localStorage.getItem('alpargatas-api-token')) {
        window.location.href = `${process.env.REACT_APP_REDIRECT_LOGIN}`;
        return;
      }
      const user = await getActualUser();

      if (user.roles === null) {
        window.location.href = `${process.env.REACT_APP_REDIRECT_LOGOUT}`;
        return;
      }

      const roles = user.roles.map((item: string) => item.toUpperCase())

      if (roles.indexOf(adminRole.toUpperCase()) < 0) {
        window.location.href = `${process.env.REACT_APP_REDIRECT_LOGOUT}`;
        return;
      }

      setReady(true);
    }
    init();
  }, [])

  if (!ready) {
    return null;
  }

  return (
    <AppContainer>
      <AppLeftContent>
        <SideBar />
      </AppLeftContent>
      <AppRightContent>
        <AppContent>
          <Routes />
        </AppContent>
      </AppRightContent>
    </AppContainer>
  )
}

export default App;
