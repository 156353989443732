import styled from "styled-components";

const CreateAndEditAuthorContainer = styled.div``;

const ContentUploadOrSelectContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;

  iframe {
    margin-top: 10px;
    width: 600px;
    height: 340px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ThumbnailUploadContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;

  img {
    margin-top: 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ContentThumbnail = styled.img`
  max-width: 600px;
`;

export {
  CreateAndEditAuthorContainer,
  ContentUploadOrSelectContainer,
  ThumbnailUploadContainer,
  ContentThumbnail,
};
