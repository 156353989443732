import styled from 'styled-components';

export const Container = styled.div`
  button {
    padding: 0;

    a.csv-download-button {
      display: block;
      color: white;
      text-decoration: none;
      padding: 15px 20px;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  gap: 25px;
  margin: 30px 0;
`;

export const Actions = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  button {

    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }

    &.generate-button {
      padding: 15px 20px;
    }
  }

  form {
    margin-left: auto;
    display: flex;
    align-items: stretch;
    gap: 10px;

    input {
      max-width: 80%;
      width: 300px;
    }

    button {
      padding: 0 16px;
    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 16px;
`;

export const Card = styled.div`
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  padding: 16px;
  width: 100%;
  max-width: 390px;

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #32325D;
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #8898AA;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
`;