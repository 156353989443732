import convertToContent from "../helpers/converters/convert-content";
import httpClient from "../http-client";
import Category from "../models/category";
//import ContentForCreate from "../models/for-create/content";
//import ContentForUpdate from "../models/for-update/content";
//import ContentFromReponse from "../models/from-api-response/content";
import { uploadFile } from "./files";

const getCategory = async (categoryId: string) => {
  const category = (
    await httpClient.get<any>(`categories/${categoryId}`)
  ).data;

  //let convertedContent = {} as Content;
  //if (content && Object.keys(content).length) {
  //  convertedContent = convertToContent(content);
  //}

  return category;
};

const getAllCategories = async () => {
  const categories = (await httpClient.get<any[]>("categories"))
    .data;

  //let convertedContents = [] as Content[];
  //if (contents && contents.length) {
  //  convertedContents = contents.map(convertToContent);
  //}

  return categories;
};

const createCategory = async (newCategory: any) => {
  const createCategoryResponse = (
    await httpClient.post<any>(`categories`, newCategory)
  ).data;

  return createCategoryResponse;
};

const updateCategory = async (
  categoryId: string,
  newContentForCategory: any
) => {
  await httpClient.put(
    `categories/${categoryId}`,
    newContentForCategory
  );
};

const deleteCategory = async (categoryId: string) => {
  await httpClient.delete(
    `categories/${categoryId}`
  );
};

const changeCategoryThumbnail = async (
  categoryId: string,
  formData: FormData
) => {
  const uploadedFile = await uploadFile(formData);
  await httpClient.patch(`categories/${categoryId}/thumbnail`, {
    thumbnail: uploadedFile.reference,
  });
};

const changeCategoryLogo = async (
  categoryId: string,
  formData: FormData
) => {
  const uploadedFile = await uploadFile(formData);
  await httpClient.patch(`categories/${categoryId}/logo`, {
    logo: uploadedFile.reference,
  });
};

const activateOrInactivateCategory = async (
  categoryId: any,
  activate: boolean
) => {
  await httpClient.put(
    `categories/${categoryId}/active`,
    { is_active: activate }
  );
};

export {
  getAllCategories,
  deleteCategory,
  updateCategory,
  createCategory,
  changeCategoryThumbnail,
  changeCategoryLogo,
  getCategory,
  activateOrInactivateCategory,
};
