import React from "react";
import { useHistory } from "react-router-dom";
import { login } from './../../services/login';
import { useEffect } from "react";
import { useCallback } from "react";

const Login: React.FC = () => {

  const history = useHistory();

  const tryLogin = useCallback(async () => {
    try {
      await login();
      history.push('/home');
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_REDIRECT_LOGIN}`;
    }
  }, [history]);

  useEffect(() => {
    tryLogin();
  }, [tryLogin])

  return (
    <></>
  );
};

export default Login;
