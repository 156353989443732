import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import SwitchElement from "react-switch";
import { BiTrash, BiDownload } from "react-icons/bi";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import SelectSambaVideosContent from "../../components/SelectSambaVideosContent";
import { hideModal, showModal } from "../../helpers/modal";
import MediaFromResponse from "../../models/from-api-response/media";
import {
  changeLessonThumbnail as changeContentThumbnailService,
  createLesson as createContentService,
  deleteLesson,
  getLesson as getContentService,
  updateLesson as updateContentService,
} from "../../services/lessons";
import {
  createExtraMaterial as createExtraMaterialService,
  deleteExtraMaterial as deleteExtraMaterialService,
} from "../../services/extra-materials";
import { getMedia } from "../../services/medias";
import CutImage from "../../components/CutImage";
import checkEmptyString from "../../helpers/check-empty-string";
// import Author from "../../models/author";
import SelectSubtitles from "../../components/SelectSubtitles";
import {
  ContentThumbnail,
  ContentUploadOrSelectContainer,
  CreateAndEditContentContainer,
  ThumbnailUploadContainer,
  ButtonGroupInline,
  ExtraMaterialList,
  ExtraMaterial,
  ExtraMaterialsInput,
  SwitchContainer,
} from "./style";
import { uploadFile } from "../../services/files";
import UploadScorm from "../../components/UploadScorm";
import { downloadMaterial } from "../../helpers/downloadMaterial";

interface CreateAndEditContentProps {
  contentId: string;
}

const CreateAndEditContent: React.FC = () => {
  const { contentId } = useParams<CreateAndEditContentProps>();

  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [reference, setReference] = useState("");
  // const [authors, setAuthors] = useState("" as unknown as Author[]);
  const [duration, setDuration] = useState(0);
  const [durationMask, setDurationMask] = useState("0:00");
  const [type, setType] = useState("");
  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>();
  const [thumbnail, setThumbnail] = useState("");

  const [extraMaterials, setExtraMaterials] = useState<any>([] as any[]);
  const [extraMaterialsToBeDeleted, setExtraMaterialsToBeDeleted] =
    useState<any>([] as any[]);

  const [hasSubtitle, setHasSubtitle] = useState(false);
  const [switchState, setSwitchState] = useState<string>("video");
  const [isScormVersion12, setIsScormVersion12] = useState<boolean>(false);

  const inputMask = (value: any) => {
    value = value.replace(/D/g, "");
    value = value.replace(/^([0-9]?[0-9]?[0-9])([0-5][0-9])$/, "$1:$2");
    setDurationMask(value);

    let timeSplit = value.split(":");
    let seconds = 0;
    if (timeSplit.length > 1) {
      seconds = timeSplit[0] * 60 * 60 + timeSplit[1] * 60;
    } else {
      seconds = timeSplit[0] * 60;
    }

    setDuration(seconds);
  };

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setReference(referenceUrl);
          setDuration(firstFile.fileInfo.duration / 1000);
          setType(firstFile.qualifier);
        }
      }
      setHasSubtitle(sambaVideosContent.captions.length > 0);
    }

    hideModal();
  };

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título valido para o conteúdo.");
      }

      if (!reference) {
        throw new Error("Selecione um conteúdo antes.");
      }

      if (switchState === "scorm" && duration === 0) {
        throw new Error("Duração média deve ser maior que zero.");
      }

      const createdContent = await createContentService({
        title: title,
        description: description.trim().length ? description : undefined,
        duration: switchState === "scorm" ? duration : duration,
        reference: reference,
        type:
          switchState === "scorm"
            ? switchState.toUpperCase()
            : type.toLocaleUpperCase(),
        is_library: false,
        info: {
          scorm_version:
            switchState === "scorm"
              ? isScormVersion12
                ? "1.2"
                : "2004"
              : undefined,
        },
      });

      if (thumbnailFileToUpload) {
        try {
          await changeContentThumbnail(
            thumbnailFileToUpload,
            createdContent.content_id
          );
        } catch (error) {
          await deleteLesson(createdContent.content_id);

          throw new Error(
            "Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      let uploadedMaterials = [] as { filename: string; reference: string }[];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials) {
          const formData = new FormData();
          formData.append("file", material);

          await uploadFile(formData).then(({ reference, name }) =>
            uploadedMaterials.push({ reference, filename: name })
          );
        }
      }

      if (uploadedMaterials.length >= 1) {
        await createExtraMaterialService(
          createdContent.content_id,
          uploadedMaterials
        );
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Episódio criado com sucesso!",
        icon: "success",
      });

      goToContents();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao criar o episódio. " + error.message,
        icon: "error",
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título valido para o episódio.");
      }

      if (!reference) {
        throw new Error("Selecione um conteúdo antes.");
      }

      if (switchState === "scorm" && duration === 0) {
        throw new Error("Duração média deve ser maior que zero.");
      }

      await updateContentService(contentId, {
        title: title,
        description: description || undefined,
        duration: switchState === "scorm" ? duration : duration,
        reference: reference,
        type:
          switchState === "scorm"
            ? switchState.toUpperCase()
            : type.toLocaleUpperCase(),
        is_active: true,
        is_library: false,
        info: {
          scorm_version:
            switchState === "scorm"
              ? isScormVersion12
                ? "1.2"
                : "2004"
              : undefined,
        },
      });

      if (thumbnailFileToUpload) {
        try {
          await changeContentThumbnail(thumbnailFileToUpload, contentId);
        } catch (error) {
          throw new Error(
            "Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      for (let material of extraMaterialsToBeDeleted) {
        await deleteExtraMaterialService(material.id);
      }

      let uploadedMaterials = [] as { filename: string; reference: string }[];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials.filter(
          (extraMaterial: any) => !extraMaterial.alreadySaved
        )) {
          const formData = new FormData();
          formData.append("file", material);

          await uploadFile(formData).then(({ reference, name }) =>
            uploadedMaterials.push({ reference, filename: name })
          );
        }
      }

      if (uploadedMaterials.length >= 1) {
        await createExtraMaterialService(contentId, uploadedMaterials);
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Curso editado com sucesso!",
        icon: "success",
      });

      goToContents();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao editar o episódio. " + error.message,
        icon: "error",
      });
    }
  };

  const changeContentThumbnail = async (
    localThumbnailFileToUpload: File,
    localContentId: string
  ) => {
    const formData = new FormData();
    formData.append("file", localThumbnailFileToUpload);
    formData.append(
      "name",
      `content_thumbnail_${localContentId}.${localThumbnailFileToUpload.type}`
    );
    formData.append(
      "description",
      `thumbnail do conteúdo de id ${localContentId}`
    );

    await changeContentThumbnailService(localContentId, formData);
  };

  const selectSubtitles = () => {
    showModal("Selecionar Legenda", <SelectSubtitles reference={reference} />);
  };

  const selectContent = () => {
    showModal(
      "Selecionar Conteúdo",
      <SelectSambaVideosContent onSelectContent={onSelectContent} />
    );
  };

  const selectThumbnail = () => {
    showModal(
      "Selecionar Imagem de Capa",
      <CutImage aspect={1.812} onCutImage={onCutImage} />
    );
  };

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  const addExtraMaterial = (event: any) => {
    event.preventDefault();

    if (event) {
      let file = event.target.files[0];
      file.alreadySaved = false;

      setExtraMaterials([...(extraMaterials || []), file]);
    }
  };

  const removeExtraMaterial = (extraMaterialToBeRemoved: any) => {
    if (extraMaterialToBeRemoved.alreadySaved) {
      setExtraMaterialsToBeDeleted([
        ...(extraMaterialsToBeDeleted || []),
        extraMaterialToBeRemoved,
      ]);
    }

    let localExtraMaterials = [...(extraMaterials || [])];
    localExtraMaterials = localExtraMaterials.filter(
      (extraMaterial) => extraMaterial !== extraMaterialToBeRemoved
    );

    setExtraMaterials(localExtraMaterials);
  };

  const goToContents = () => {
    history.push("/trails/contents");
  };

  function formatTime(sec: any) {
    var hrs = Math.floor(sec / 3600);
    var min = Math.floor((sec - hrs * 3600) / 60);
    var seconds = sec - hrs * 3600 - min * 60;
    seconds = Math.round(seconds * 100) / 100;

    var result = hrs < 10 ? "0" + hrs : hrs;
    result += ":" + (min < 10 ? "0" + min : min);
    return result;
  }

  const getContent = useCallback(async () => {
    if (contentId) {
      const content = await getContentService(contentId);

      if (content && Object.keys(content).length) {
        if (content.type.toUpperCase() === "VIDEO") {
          const currentMedia = await getMedia(
            content.reference.split("/")[
            content.reference.split("/").length - 1
            ]
          );
          setHasSubtitle(currentMedia.captions.length > 0);
        }
        setTitle(content.title);
        setDescription(content.description);
        setReference(content.reference);
        setDuration(content.duration);
        setThumbnail(content.thumbnail);
        setType(content.type);

        if (content.type.toUpperCase() === "SCORM") {
          if (content.info.scorm_version === "1.2") {
            setIsScormVersion12(true);
          }
          setSwitchState("scorm");

          setDurationMask(formatTime(content.duration) as string);
          setDuration(content.duration);
        }

        const localExtraMaterials =
          content.extra_materials &&
          content.extra_materials.length &&
          content.extra_materials.map((extraMaterial: any) => ({
            id: extraMaterial.extra_material_id,
            name: extraMaterial.name.slice(21),
            alreadySaved: true,
            reference: extraMaterial.reference,
          }));

        setExtraMaterials(localExtraMaterials);
      }
    }
  }, [contentId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (contentId) {
      return true;
    }

    return false;
  }, [contentId]);

  return (
    <CreateAndEditContentContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/trails/contents">Séries</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Episódios</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Episódio
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description || ""}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="reference">Capa</label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Capa
            </DefaultButton>

            {thumbnail && <ContentThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Conteúdo
          </label>

          <SwitchContainer>
            <SwitchElement
              onChange={(checked) => {
                setSwitchState(checked ? "video" : "scorm");
                setReference("");
              }}
              checked={switchState === "video"}
              uncheckedIcon={false}
              checkedIcon={false}
              offColor="#525f7f"
              onColor="#525f7f"
              height={34}
              width={60}
              handleDiameter={25.5}
            />
            <label>{switchState === "video" ? "vídeo" : "scorm"}</label>
          </SwitchContainer>

          <ContentUploadOrSelectContainer>
            {switchState === "video" ? (
              <ButtonGroupInline>
                <DefaultButton type="button" onClick={selectContent}>
                  Selecionar Conteúdo
                </DefaultButton>
                {reference && !hasSubtitle && (
                  <DefaultButton type="button" onClick={selectSubtitles}>
                    Selecionar Legenda
                  </DefaultButton>
                )}
              </ButtonGroupInline>
            ) : (
              <UploadScorm
                setReference={setReference}
                setIsScormVersion12={setIsScormVersion12}
              />
            )}
            {reference ? (
              <iframe
                title="referenced-video"
                allowFullScreen
                src={reference}
                frameBorder={0}
              ></iframe>
            ) : (
              <></>
            )}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>

        {switchState === "scorm" && (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="duration">
              Duração Média (no formato HH:mm, exemplo: 3:59)
            </label>
            <DefaultInput
              value={durationMask}
              id="duration"
              type="text"
              min={0}
              maxLength={4}
              onFocus={(e) => {
                e.target.value = "";
              }}
              onChange={(e) => inputMask(e.target.value)}
            />
          </DefaultCreationFormGroup>
        )}

        <DefaultCreationFormGroup>
          <label>Materiais de Apoio</label>
          <ExtraMaterialsInput
            type="file"
            onChange={(e) => addExtraMaterial(e)}
          />
          <ExtraMaterialList>
            {extraMaterials && extraMaterials.length ? (
              extraMaterials.map((material: any, index: number) => (
                <ExtraMaterial key={index}>
                  <h5>{material.name}</h5>
                  {material.reference ? (
                    <button
                      type="button"
                      onClick={() => downloadMaterial(material)}
                    >
                      <BiDownload size={14} />
                    </button>
                  ) : (
                    <></>
                  )}

                  <button
                    onClick={() => removeExtraMaterial(material)}
                    type="button"
                  >
                    <BiTrash size={14} />
                  </button>
                </ExtraMaterial>
              ))
            ) : (
              <p>Não foi encontrado nenhum material de apoio.</p>
            )}
          </ExtraMaterialList>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToContents}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateContent(e) : createContent(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditContent;
