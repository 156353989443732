import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { BiTrash, BiDownload } from "react-icons/bi";
import Swal from "sweetalert2";
import SwitchElement from "react-switch";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import {
  createExtraMaterial as createExtraMaterialService,
  deleteExtraMaterial as deleteExtraMaterialService,
} from "../../services/extra-materials";
import { uploadFile } from "../../services/files";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import SelectSambaVideosContent from "../../components/SelectSambaVideosContent";
import SelectSubtitles from "../../components/SelectSubtitles";
import { hideModal, showModal } from "../../helpers/modal";
import MediaFromResponse from "../../models/from-api-response/media";
import {
  changeContentBanner as changeContentBannerService,
  changeContentThumbnail as changeContentThumbnailService,
  createContent as createContentService,
  deleteContent,
  getContent as getContentService,
  updateContent as updateContentService,
} from "../../services/contents";
import CutImage from "../../components/CutImage";
import checkEmptyString from "../../helpers/check-empty-string";
import Select from "react-select";
import Author from "../../models/author";
import { getAllAuthors } from "../../services/authors";
import Category from "../../models/category";
import { getAllCategories } from "../../services/categories";
import { getMedia } from "../../services/medias";
import {
  ContentThumbnail,
  ContentUploadOrSelectContainer,
  CreateAndEditContentContainer,
  ThumbnailUploadContainer,
  Switch,
  ButtonGroupInline,
  ExtraMaterialList,
  ExtraMaterial,
  ExtraMaterialsInput,
  SwitchContainer,
} from "./style";
import UploadScorm from "../../components/UploadScorm";
import durationToText from "../../helpers/durationToText";
import { downloadMaterial } from "../../helpers/downloadMaterial";
interface CreateAndEditContentProps {
  contentId: string;
}

const CreateAndEditContent: React.FC = () => {
  const { contentId } = useParams<CreateAndEditContentProps>();

  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [reference, setReference] = useState("");
  const [authors, setAuthors] = useState("" as unknown as Author[]);
  const [selectedAuthors, setSelectedAuthors] = useState(
    [] as unknown as { label: string; value: string }[]
  );

  const [categories, setCategories] = useState("" as unknown as Category[]);
  const [selectedCategory, setSelectedCategory] = useState(
    [] as unknown as { label: string; value: string }[]
  );

  const [duration, setDuration] = useState(0);
  const [durationMask, setDurationMask] = useState("0:00");
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [type, setType] = useState("");

  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>();
  const [thumbnail, setThumbnail] = useState("");

  const [bannerFileToUpload, setBannerFileToUpload] = useState<File>();
  const [banner, setBanner] = useState("");
  const [bannerPreview, setBannerPreview] = useState("");

  const [hasSubtitle, setHasSubtitle] = useState(false);

  const [extraMaterials, setExtraMaterials] = useState<any>([] as any[]);
  const [extraMaterialsToBeDeleted, setExtraMaterialsToBeDeleted] =
    useState<any>([] as any[]);
  const [switchState, setSwitchState] = useState<string>("video");
  const [isScormVersion12, setIsScormVersion12] = useState<boolean>(false);

  const inputMask = (value: any) => {
    value = value.replace("0:", "")
    value = value.replace("0:0", "")
    value = value.replace(/D/g, "");
    value = value.replace(/^([0-9]?[0-9]?[0-9])([0-5][0-9])$/, "$1:$2");

    let timeSplit = value.split(":");
    let timeFormatted;
    if (value.length === 1) {
      timeFormatted = "0:0"
    } else if (value.length === 2) {
      timeFormatted = "0"
    } else {
      timeFormatted = ""
    }
    let seconds
    if (timeSplit.length > 1) {
      seconds = timeSplit[0] * 60 * 60 + timeSplit[1] * 60;
    } else {
      seconds = timeSplit[0] * 60;
    }

    setDurationMask(timeFormatted + value);
    setDuration(seconds);
  };

  const secondsToTime = (duration: number) => {
    let timeFormatted = durationToText(duration);

    setDurationMask(timeFormatted);

    return timeFormatted;
  };

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setReference(referenceUrl);
          setDuration(firstFile.fileInfo.duration / 1000);
          setType(firstFile.qualifier);
        }
      }
      setHasSubtitle(sambaVideosContent.captions.length > 0);
    }

    hideModal();
  };

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título valido para o conteúdo.");
      }

      if (isHighlighted && !bannerPreview) {
        throw new Error(
          "Informe uma imagem de banner destaque para o conteúdo."
        );
      }

      if (!reference) {
        throw new Error("Selecione um conteúdo antes.");
      }

      if (!selectedAuthors || !selectedAuthors.length) {
        throw new Error("Selecione um tema antes.");
      }

      if (!selectedCategory || !selectedCategory.length) {
        throw new Error("Selecione uma categoria antes.");
      }

      if (switchState === "scorm" && duration === 0) {
        throw new Error("Duração média deve ser maior que zero.");
      }

      const createdContent = await createContentService({
        title: title,
        description: description.trim().length ? description : undefined,
        duration: switchState === "scorm" ? duration : duration,
        reference: reference,
        type:
          switchState === "scorm"
            ? switchState.toUpperCase()
            : type.toLocaleUpperCase(),
        is_library: true,
        categories:
          selectedCategory &&
          selectedCategory.map((category) => ({ category_id: category.value })),
        authors:
          selectedAuthors &&
          selectedAuthors.map((author) => ({ author_id: author.value })),
        info: {
          is_highlighted: isHighlighted,
          scorm_version:
            switchState === "scorm"
              ? isScormVersion12
                ? "1.2"
                : "2004"
              : undefined,
        },
      });

      if (thumbnailFileToUpload) {
        try {
          await changeContentThumbnail(
            thumbnailFileToUpload,
            createdContent.content_id
          );
        } catch (error) {
          await deleteContent(createdContent.content_id);

          throw new Error(
            "Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      if (isHighlighted && bannerFileToUpload) {
        try {
          await changeContentBanner(
            bannerFileToUpload,
            createdContent.content_id
          );
        } catch (error) {
          await deleteContent(createdContent.content_id);

          throw new Error(
            "Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      let uploadedMaterials = [] as { filename: string; reference: string }[];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials) {
          const formData = new FormData();
          formData.append("file", material);

          await uploadFile(formData).then(({ reference, name }) =>
            uploadedMaterials.push({ reference, filename: name })
          );
        }
      }

      if (uploadedMaterials.length >= 1) {
        await createExtraMaterialService(
          createdContent.content_id,
          uploadedMaterials
        );
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Conteúdo criado com sucesso!",
        icon: "success",
      });

      goToContents();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao criar o conteúdo. " + error.message,
        icon: "error",
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título valido para o curso.");
      }

      if (isHighlighted && !bannerPreview) {
        throw new Error(
          "Informe uma imagem de banner destaque para o conteúdo."
        );
      }

      if (!reference) {
        throw new Error("Selecione um conteúdo antes.");
      }

      if (!selectedAuthors || !selectedAuthors.length) {
        throw new Error("Selecione um tema antes.");
      }

      if (!selectedCategory || !selectedCategory.length) {
        throw new Error("Selecione uma categoria antes.");
      }

      if (switchState === "scorm" && duration === 0) {
        throw new Error("Duração média deve ser maior que zero.");
      }

      await updateContentService(contentId, {
        title: title,
        description: description || undefined,
        duration: switchState === "scorm" ? duration : duration,
        reference: reference,
        type:
          switchState === "scorm"
            ? switchState.toUpperCase()
            : type.toLocaleUpperCase(),
        is_active: true,
        is_library: true,
        categories:
          selectedCategory &&
          selectedCategory.map((category) => ({ category_id: category.value })),
        authors:
          selectedAuthors &&
          selectedAuthors.map((author) => ({ author_id: author.value })),
        info: {
          scorm_version:
            switchState === "scorm"
              ? isScormVersion12
                ? "1.2"
                : "2004"
              : undefined,
          is_highlighted: isHighlighted,
          ...(isHighlighted && banner && { banner }),
        },
      });

      if (thumbnailFileToUpload) {
        try {
          await changeContentThumbnail(thumbnailFileToUpload, contentId);
        } catch (error) {
          throw new Error(
            "Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      if (isHighlighted && bannerFileToUpload) {
        try {
          await changeContentBanner(bannerFileToUpload, contentId);
        } catch (error) {
          throw new Error(
            "Erro ao fazer upload da imagem do banner. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      for (let material of extraMaterialsToBeDeleted) {
        await deleteExtraMaterialService(material.id);
      }

      let uploadedMaterials = [] as { filename: string; reference: string }[];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials.filter(
          (extraMaterial: any) => !extraMaterial.alreadySaved
        )) {
          const formData = new FormData();
          formData.append("file", material);

          await uploadFile(formData).then(({ reference, name }) =>
            uploadedMaterials.push({ reference, filename: name })
          );
        }
      }

      if (uploadedMaterials.length >= 1) {
        await createExtraMaterialService(contentId, uploadedMaterials);
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Curso editado com sucesso!",
        icon: "success",
      });

      goToContents();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao editar o curso. " + error.message,
        icon: "error",
      });
    }
  };

  const changeContentThumbnail = async (
    localThumbnailFileToUpload: File,
    localContentId: string
  ) => {
    const formData = new FormData();
    formData.append("file", localThumbnailFileToUpload);
    formData.append(
      "name",
      `content_thumbnail_${localContentId}.${localThumbnailFileToUpload.type}`
    );
    formData.append(
      "description",
      `thumbnail do conteúdo de id ${localContentId}`
    );

    await changeContentThumbnailService(localContentId, formData);
  };

  const changeContentBanner = async (
    localThumbnailFileToUpload: File,
    localContentId: string
  ) => {
    const formData = new FormData();
    formData.append("file", localThumbnailFileToUpload);
    formData.append(
      "name",
      `content_thumbnail_${localContentId}.${localThumbnailFileToUpload.type}`
    );
    formData.append(
      "description",
      `thumbnail do conteúdo de id ${localContentId}`
    );

    await changeContentBannerService(localContentId, formData);
  };

  const selectSubtitles = () => {
    showModal("Selecionar Legenda", <SelectSubtitles reference={reference} />);
  };

  const selectContent = () => {
    showModal(
      "Selecionar Conteúdo",
      <SelectSambaVideosContent onSelectContent={onSelectContent} />
    );
  };

  const selectThumbnail = () => {
    showModal(
      "Selecionar Imagem de Capa",
      <CutImage aspect={1.812} onCutImage={onCutImage} />
    );
  };

  const selectBanner = () => {
    showModal(
      "Selecionar Imagem do Destaque",
      <CutImage aspect={1.812} onCutImage={onCutBanner} />
    );
  };

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };
  const onCutBanner = (file: File) => {
    if (file) {
      setBannerFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setBannerPreview(`${reader.result}`);

      hideModal();
    }
  };

  const addExtraMaterial = (event: any) => {
    event.preventDefault();

    if (event) {
      let file = event.target.files[0];
      file.alreadySaved = false;

      setExtraMaterials([...(extraMaterials || []), file]);
    }
  };

  const removeExtraMaterial = (extraMaterialToBeRemoved: any) => {
    if (extraMaterialToBeRemoved.alreadySaved) {
      setExtraMaterialsToBeDeleted([
        ...(extraMaterialsToBeDeleted || []),
        extraMaterialToBeRemoved,
      ]);
    }

    let localExtraMaterials = [...(extraMaterials || [])];
    localExtraMaterials = localExtraMaterials.filter(
      (extraMaterial) => extraMaterial !== extraMaterialToBeRemoved
    );

    setExtraMaterials(localExtraMaterials);
  };

  const goToContents = () => {
    history.push("/contents");
  };

  const getContent = useCallback(async () => {
    const foundAuthors = await getAllAuthors();
    const foundCategories = await getAllCategories();
    if (foundAuthors) setAuthors(foundAuthors);
    if (foundCategories) setCategories(foundCategories);
    if (contentId) {
      const content = await getContentService(contentId);
      if (content && Object.keys(content).length) {
        if (content.content.type.toUpperCase() === "VIDEO") {
          const currentMedia = await getMedia(
            content.content.reference.split("/")[
            content.content.reference.split("/").length - 1
            ]
          );
          setHasSubtitle(currentMedia.captions.length > 0);
        }
        setTitle(content.content.title);
        setDescription(content.content.description);
        setReference(content.content.reference);
        setDuration(content.content.duration);
        setThumbnail(content.content.thumbnail);
        setType(content.content.type);
        setIsHighlighted(content.content.info.is_highlighted);
        setBanner(content.content.info.banner);
        setBannerPreview(content.content.info.banner);

        if (content.content.type.toUpperCase() === "SCORM") {
          setSwitchState("scorm");
          setDuration(content.content.duration);
          secondsToTime(content.content.duration);
        }
        if (content.content.authors) {
          const selectedAuthors = content.content.authors.map(
            (author: Author) => ({
              label: author.name,
              value: author.id,
            })
          );
          setSelectedAuthors(selectedAuthors);
        }
        if (content.content.categories) {
          const selectedCategories = content.content.categories.map(
            (category: Category) => ({
              label: category.title,
              value: category.id,
            })
          );
          setSelectedCategory(selectedCategories);
        }

        const localExtraMaterials =
          content.content.extra_materials &&
          content.content.extra_materials.length &&
          content.content.extra_materials.map((extraMaterial: any) => ({
            id: extraMaterial.extra_material_id,
            name: extraMaterial.name.slice(21),
            alreadySaved: true,
            reference: extraMaterial.reference,
          }));

        setExtraMaterials(localExtraMaterials);
      }
    }
  }, [contentId]);

  const authorsToBeSelected = useMemo(() => {
    if (authors && authors.length) {
      return authors.map((author) => ({
        label: author.name,
        value: `${author.author_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [authors]);

  const categoriesToBeSelected = useMemo(() => {
    if (categories && categories.length) {
      return categories.map((category) => ({
        label: category.title,
        value: `${category.category_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [categories]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (contentId) {
      return true;
    }

    return false;
  }, [contentId]);

  return (
    <CreateAndEditContentContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/contents">Cursos</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Cursos</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Cursos
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description || ""}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="reference">Capa</label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Capa
            </DefaultButton>

            {thumbnail && <ContentThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Conteúdo
          </label>

          <SwitchContainer>
            <SwitchElement
              onChange={(checked) => {
                setSwitchState(checked ? "video" : "scorm");
                setReference("");
              }}
              checked={switchState === "video"}
              uncheckedIcon={false}
              checkedIcon={false}
              offColor="#525f7f"
              onColor="#525f7f"
              height={34}
              width={60}
              handleDiameter={25.5}
            />
            <label>{switchState === "video" ? "vídeo" : "scorm"}</label>
          </SwitchContainer>

          <ContentUploadOrSelectContainer>
            {switchState === "video" ? (
              <ButtonGroupInline>
                <DefaultButton type="button" onClick={selectContent}>
                  Selecionar Conteúdo
                </DefaultButton>
                {reference && !hasSubtitle && (
                  <DefaultButton type="button" onClick={selectSubtitles}>
                    Selecionar Legenda
                  </DefaultButton>
                )}
              </ButtonGroupInline>
            ) : (
              <UploadScorm
                setReference={setReference}
                setIsScormVersion12={setIsScormVersion12}
              />
            )}
            {reference ? (
              <iframe
                title="referenced-video"
                allowFullScreen
                src={reference}
                frameBorder={0}
              ></iframe>
            ) : (
              <></>
            )}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>

        {switchState === "scorm" && (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="duration">
              Duração Média (no formato hhmm)
            </label>
            <DefaultInput
              value={durationMask}
              id="duration"
              type="text"
              min={0}
              // maxLength={4}
              onFocus={(e) => {
                e.target.value = "";
              }}
              onChange={(e) => inputMask(e.target.value)}
            />
          </DefaultCreationFormGroup>
        )}

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="tags">
            Temas
          </label>
          <Select
            styles={{
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
                width: "100%",
              }),
            }}
            options={authorsToBeSelected}
            value={selectedAuthors}
            onChange={(options) => {
              options &&
                !window.isNaN(options.length) &&
                setSelectedAuthors(
                  options.map((opt) => ({ label: opt.label, value: opt.value }))
                );
            }}
            id="authors"
            isMulti
            placeholder="Selecione um ou mais temas para este curso"
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="tags">
            Categorias
          </label>
          <Select
            styles={{
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
                width: "100%",
              }),
            }}
            options={categoriesToBeSelected}
            value={selectedCategory}
            onChange={(options) =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedCategory(
                options.map((opt) => ({ label: opt.label, value: opt.value }))
              )
            }
            id="authors"
            isMulti
            placeholder="Selecione uma categoria para este conteúdo."
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label>Destaque</label>
          <Switch>
            <input
              type="checkbox"
              checked={isHighlighted}
              onChange={(e) => setIsHighlighted(!isHighlighted)}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>

        {isHighlighted && (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="reference">
              Banner
            </label>

            <ThumbnailUploadContainer>
              <DefaultButton type="button" onClick={selectBanner}>
                Selecionar Imagem do Banner
              </DefaultButton>

              {bannerPreview && <ContentThumbnail src={bannerPreview} />}
            </ThumbnailUploadContainer>
          </DefaultCreationFormGroup>
        )}

        <DefaultCreationFormGroup>
          <label>Materiais de Apoio</label>
          <ExtraMaterialsInput
            type="file"
            onChange={(e) => addExtraMaterial(e)}
          />
          <ExtraMaterialList>
            {extraMaterials && extraMaterials.length ? (
              extraMaterials.map((material: any, index: number) => (
                <ExtraMaterial key={index}>
                  <h5>{material.name}</h5>
                  {material.reference ? (
                    <button
                      type="button"
                      onClick={() => downloadMaterial(material)}
                    >
                      <BiDownload size={14} />
                    </button>
                  ) : (
                    <></>
                  )}

                  <button
                    onClick={() => removeExtraMaterial(material)}
                    type="button"
                  >
                    <BiTrash size={14} />
                  </button>
                </ExtraMaterial>
              ))
            ) : (
              <p>Não foi encontrado nenhum material de apoio.</p>
            )}
          </ExtraMaterialList>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToContents}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateContent(e) : createContent(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditContent;
