import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";
import DefaultButton from "../../components/DefaultButton";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import convertSecondsToTimeText from "../../helpers/seconds-to-time-text";
import Author from "../../models/author";
import { getAllAuthors, deleteAuthor } from "../../services/authors";
import {
  activateOrInactivateCourse as activateOrInactivateCourseService,
} from "../../services/courses";
import { AuthorsContainer } from "./style";

const Authors: React.FC = () => {
  const history = useHistory();

  const [authors, setAuthors] = useState([] as Author[]);

  const getAuthors = async () => {
    const authors = await getAllAuthors();
    if (authors && authors.length) {
      setAuthors(authors);
    }
  };

  const editAuthor = useCallback(
    (authorId: any) => {
      history.push(`edit-author/${authorId}`);
    },
    [history]
  );

  const removeAuthor = useCallback(async (authorId: any) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover este tema?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteAuthor(authorId);

          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Tema excluido com sucesso!",
          });

          await getAuthors();
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "Erro ao excluir tema",
            text: e.message,
          });
        }
      }
    });
  }, []);

  const createAuthor = async () => {
    history.push("create-author");
  };

  const activateOrInactivateCourse = useCallback(
    async (courseId: string, activate: boolean) => {
      Swal.fire({
        title: "Confirmação",
        text: `Tem certeza que deseja ${activate ? "ativar" : "inativar"
          } este tema?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateCourseService(courseId, activate);

            getAuthors();
          } catch (error) {
            Swal.fire({
              title: "Erro",
              text: `Erro ao ${activate ? "ativar" : "inativar"} tema!`,
            });
          }
        }
      });
    },
    []
  );

  const authorsToBeShown = useMemo(() => {
    return authors && authors.length
      ? authors.map((author) => ({
        id: author.author_id,
        name: author.name,
        description: author.description,
        actions: (
          <>
            <DefaultButton
              className="small info"
              onClick={() => editAuthor(author.author_id)}
            >
              <BiEdit />
            </DefaultButton>{" "}

            <DefaultButton
              onClick={() => removeAuthor(author.author_id)}
              className="small danger"
              title="Excluir Tema"
            >
              <BiTrash />
            </DefaultButton>
          </>
        ),
      }))
      : [];
  }, [authors, editAuthor, removeAuthor, activateOrInactivateCourse]);

  useEffect(() => {
    getAuthors();
  }, []);

  return (
    <AuthorsContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Temas</span>]}
      />
      <DefaultPageTitle>Temas</DefaultPageTitle>
      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createAuthor}>Criar Tema</DefaultButton>
      </CreateButtonDefaultContainer>
      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: "name",
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: "description",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={authorsToBeShown}
        emptyListMessage={"Não foram encontrados temas cadastrados!"}
      />
    </AuthorsContainer>
  );
};

export default Authors;
