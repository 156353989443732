const durationToText = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  
  let timeText = "";
  if (hours) {
    timeText += hours + ":";
  }

  if (minutes) {
    timeText += minutes;
  }

  return timeText;
};

export default durationToText;
