import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";
import DefaultButton from "../../components/DefaultButton";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import {default as IFAQ} from "../../models/faq";
import { deleteFaq, getAllFaqs } from "../../services/faq";

import { AuthorsContainer } from "./style";

const FAQ: React.FC = () => {
  const history = useHistory();

  const [faqs, setFaqs] = useState([] as IFAQ[]);

  const getFaqs = async () => {
    const allFaqs = await getAllFaqs();
    if (allFaqs) {
      setFaqs(allFaqs);
    }
  };

  const editFaq = useCallback(
    (faqId: any) => {
      history.push(`edit-faq/${faqId}`);
    },
    [history]
  );

  const removeFaq = useCallback(async (faqId: any) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover este FAQ?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteFaq(faqId);

          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "FAQ excluido com sucesso!",
          });

          await getFaqs();
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "Erro ao excluir FAQ",
            text: e.message,
          });
        }
      }
    });
  }, []);

  const createFaq = async () => {
    history.push("create-faq");
  };

  const faqsToBeShown = useMemo(() => {
    return faqs && faqs.length
      ? faqs.map((faq) => ({
        id: faq.faq_id,
        question: faq.question,
        answer: faq.answer,
        actions: (
          <>
            <DefaultButton
              className="small info"
              onClick={() => editFaq(faq.faq_id)}
            >
              <BiEdit />
            </DefaultButton>{" "}

            <DefaultButton
              onClick={() => removeFaq(faq.faq_id)}
              className="small danger"
              title="Excluir FAQ"
            >
              <BiTrash />
            </DefaultButton>
          </>
        ),
      }))
      : [];
  }, [faqs, editFaq, removeFaq]);

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <AuthorsContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>FAQs</span>]}
      />
      <DefaultPageTitle>FAQs</DefaultPageTitle>
      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createFaq}>Criar FAQ</DefaultButton>
      </CreateButtonDefaultContainer>
      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Pergunta</span>,
            propName: "question",
          },
          {
            headerLabel: <span>Resposta</span>,
            propName: "answer",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={faqsToBeShown}
        emptyListMessage={"Não foram encontrados FAQs cadastrados!"}
      />
    </AuthorsContainer>
  );
};

export default FAQ;
