import React from "react";
import { Switch, Route } from "react-router-dom";
import Contents from "../../pages/Contents";
import Courses from "../../pages/Courses";
import CreateAndEditContent from "../../pages/CreateAndEditContent";
import CreateAndEditCourse from "../../pages/CreateAndEditCourse";
import Authors from "../../pages/Authors";
import CreateAndEditAuthors from "../../pages/CreateAndEditAuthors";
import Categories from "../../pages/Categories";
import CreateAndEditCategory from "../../pages/CreateAndEditCategory";
import Lessons from "../../pages/Lessons";
import CreateAndEditLesson from "../../pages/CreateAndEditLesson";
import Series from "../../pages/Series";
import CreateAndEditSerie from "../../pages/CreateAndEditSerie";
import CreateAndEditFAQ from "../../pages/CreateAndEditFAQ";
import FAQ from "../../pages/FAQ";
import Users from "../../pages/Users";
import Dashboard from "../../pages/Dashboard";
import ReportTrilhaUsuario from "../../pages/ReportTrilhaUsuario";
import ReportCursoUsuario from "../../pages/ReportCursoUsuario";
import ReportAcessosUsuarios from "../../pages/ReportAcessosUsuarios";

const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/home" component={Dashboard} />

        <Route exact path="/users" component={Users} />

        <Route exact path="/authors" component={Authors} />
        <Route exact path="/create-author" component={CreateAndEditAuthors} />
        <Route exact path="/edit-author/:authorId" component={CreateAndEditAuthors} />

        <Route exact path="/categories" component={Categories} />
        <Route exact path="/create-category" component={CreateAndEditCategory} />
        <Route exact path="/edit-category/:categoryId" component={CreateAndEditCategory} />

        <Route exact path="/faqs" component={FAQ} />
        <Route exact path="/create-faq" component={CreateAndEditFAQ} />
        <Route exact path="/edit-faq/:faqId" component={CreateAndEditFAQ} />

        <Route exact path="/contents" component={Contents} />
        <Route exact path="/create-content" component={CreateAndEditContent} />
        <Route
          exact
          path="/edit-content/:contentId"
          component={CreateAndEditContent}
        />

        <Route exact path="/trails/contents" component={Lessons} />
        <Route exact path="/trails/create-content" component={CreateAndEditLesson} />
        <Route
          exact
          path="/trails/edit-content/:contentId"
          component={CreateAndEditLesson}
        />

        <Route exact path="/trails/episodes" component={Courses} />
        <Route exact path="/trails/create-episode" component={CreateAndEditCourse} />
        <Route
          exact
          path="/trails/edit-episode/:courseId"
          component={CreateAndEditCourse}
        />
        <Route exact path="/trails/series" component={Series} />
        <Route exact path="/trails/create-serie" component={CreateAndEditSerie} />
        <Route
          exact
          path="/trails/edit-serie/:serieId"
          component={CreateAndEditSerie}
        />

        <Route exact path="/reports/acessos-usuarios" component={ReportAcessosUsuarios} />
        <Route exact path="/reports/trilhas-assistidas" component={ReportTrilhaUsuario} />
        <Route exact path="/reports/cursos-assistidos" component={ReportCursoUsuario} />
      </Switch>
    </>
  );
};

export default Routes;