import httpClient from "../http-client";
import { uploadFile } from "./files";

const getCourse = async (courseId: string) => {
  const course = (await httpClient.get<any>(`trails/${courseId}`))
    .data;

  return course;
};

const getAllCourses = async () => {
  const courses = (await httpClient.get<any>("trails")).data;

  return courses;
};

const deleteCourse = async (courseId: string) => {
  await httpClient.delete<any>(`trails/${courseId}`);
};

const createCourse = async (newCourse: any) => {
  const createCourseResponse = (
    await httpClient.post<any>(`trails`, newCourse)
  ).data;

  return createCourseResponse;
};

const updateCourse = async (courseId: string, newCourse: any) => {
  await httpClient.put<any>(`trails/${courseId}`, newCourse);
};

const changeCourseThumbnail = async (courseId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`trails/${courseId}/thumbnail`, {
    thumbnail: uploadedFile.reference,
  });
};

const changeTrailBanner = async (
  trailId: string,
  formData: FormData
) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`trails/${trailId}/banner`, {
    banner: uploadedFile.reference,
  });
};

const activateOrInactivateCourse = async (
  courseId: string,
  activate: boolean
) => {
  await httpClient.put<any>(`trails/${courseId}/active`, { is_active: activate });
};

const getCoursesForSelect = async () => {
  const courses = (await httpClient.get<any>("trails/")).data;

  if (courses && courses.length) {
    return courses.map((course: any) => ({
      value: course.trail_id,
      label: course.description,
    }));
  }

  return [] as { value: string; label: string }[];
};

export {
  getAllCourses,
  deleteCourse,
  updateCourse,
  createCourse,
  getCourse,
  changeCourseThumbnail,
  changeTrailBanner,
  activateOrInactivateCourse,
  getCoursesForSelect
};
