import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubtitleUpload = styled.button`
  height: 100%;
  width: 50%;
  background: none;
  border: solid 1px var(--default-dark-gray);
  border-radius: 15px;
  cursor: pointer;
  color: var(--primary-color);

  &:hover {
    transition: all 0.3s;
    background: var(--primary-color);
    color: white;
  }

  p {
    font-size: var(--font-large);
    margin-bottom: 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ChosenFile = styled.div`
  width: 50%;
  background: none;
  border: solid 1px var(--default-dark-gray);
  border-radius: 15px;
  color: var(--primary-color);
  margin-top: 15px;
  padding: 16px;
  line-height: 24px;
`

export const LanguageContainer = styled.div`
  width: 50%;
  height: 50%;
  align-items: center;
  display: flex;
  margin: 0 0 50px;

  & > div {
    width: 100%;
  }
`;

export const ButtonGroupInline = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  button + button {
    margin-left: 10px;
  }
`
