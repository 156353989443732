import styled from "styled-components";

const DefaultInput = styled.input`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  outline: none;
  font-size: var(--font-small);
  width: 100%;
  height: 44px;
  padding: 0 10px;
  color: var(--default-gray);

`;

export default DefaultInput;
