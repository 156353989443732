import React, { useEffect, useState } from 'react';
import getActualUser from '../../helpers/get-actual-user';
import { GetCountUsersResponse, UserGetAllResponse } from '../../models/from-api-response/user';
import { getCountUsers } from '../../services/users';
import {
  Container,
  WelcomeMessage,
  CardContainer,
  Card
} from './style';

const Dashboard: React.FC = () => {
  const [user, setUser] = useState({} as UserGetAllResponse);
  const [countUser, setCountUser] = useState<GetCountUsersResponse>({ total_accept_conditions_users: 0, total_users: 0 });

  useEffect(() => {
    const getCountUsersData = async () => {
      setCountUser(await getCountUsers());
      setUser(await getActualUser());
    }
    getCountUsersData()
  }, [])

  return (
    <Container>
      <WelcomeMessage>Seja Bem Vindo, {user.name || 'Desconhecido'}!</WelcomeMessage>
      <CardContainer>
        <Card>
          <p>Total de Usuários</p>
          <h2>{countUser?.total_users}</h2>
        </Card>
        <Card>
          <p>Total de Aceites</p>
          <h2>{countUser?.total_accept_conditions_users}</h2>
        </Card>
        <Card>
          <p>Percentual de Aceite</p>
          <h2>{Math.trunc(countUser.total_accept_conditions_users * 100 / countUser.total_users)}%</h2>
        </Card>
      </CardContainer>
    </Container>
  )
}

export default Dashboard;