import httpClient from "../http-client/index";

const login = async () => {
  let token = '';
  const tokens = getAccessToken();
  if (tokens) {
    const { access_token, id_token } = tokens;
    const response = (
      await httpClient.post('/cognito/login', { access_token, id_token })
    ).data;
    token = response.api_token;
    window.localStorage.setItem('alpargatas-api-token', token);
  }
};

const getAccessToken = () => {
  const urlHash = window.location.hash;
  const findAccessToken = urlHash.search('access_token=');
  const findIdToken = urlHash.search('&id_token=');
  const findTokenType = urlHash.search('&token_type=');
  if (!findAccessToken && !findIdToken && !findTokenType) {
    return;
  }
  const accessTokenTotalLength = findAccessToken + 13;
  const idTokenTotalLength = findAccessToken + 13;
  const access_token = urlHash.substring(accessTokenTotalLength, findIdToken);
  const id_token = urlHash.substring(idTokenTotalLength, findTokenType);

  return { access_token, id_token };
};

export {login, getAccessToken };


