import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Link, useHistory, useParams } from "react-router-dom";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import Swal from "sweetalert2";

import BreadCrumb from "../../components/BreadCrumb";
import CutImage from "../../components/CutImage";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import SelectCourse from "./components/SelectCourse";
import { ReactComponent as DetachButton } from "../../assets/detach.svg";

import { hideModal, showModal } from "../../helpers/modal";
import checkEmptyString from "../../helpers/check-empty-string";

import {
  getCourse as getCourseService,
  updateCourse as updateCourseService,
  createCourse as createCourseService,
  changeCourseThumbnail as changeCourseThumbnailService,
  deleteCourse,
  changeTrailBanner,
} from "../../services/trails";
import Category from "../../models/category";
import Author from "../../models/author";
import { getAllAuthors } from "../../services/authors";
import Course from "../../models/course";
import { getAllCategories } from "../../services/categories";

import {
  ThumbnailUploadContainer,
  CourseThumbnail,
  SortLessonsContainer,
  LessonSelectionContainer,
  CreateAndEditSerieContainer,
  Switch,
} from "./style";

interface CreateAndEditSerieProps {
  serieId: string;
}

const CreateAndEditSerie: React.FC = () => {
  const history = useHistory();
  const { serieId } = useParams<CreateAndEditSerieProps>();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([] as Course[]);

  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>();
  const [thumbnail, setThumbnail] = useState("");

  const [categories, setCategories] = useState([] as Category[]);
  const [selectedCategory, setSelectedCategory] = useState(
    {} as { label: string; value: string }
  );

  const [authors, setAuthors] = useState([] as Author[]);
  const [selectedAuthor, setSelectedAuthor] = useState(
    {} as { label: string; value: string }
  );

  const [isHighlighted, setIsHighlighted] = useState(false);
  const [bannerFileToUpload, setBannerFileToUpload] = useState<File>();
  const [banner, setBanner] = useState("");
  const [bannerPreview, setBannerPreview] = useState("");

  const createCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error("Informe um nome valido para o curso.");
      }

      if (!selectedCourses || !selectedCourses.length) {
        throw new Error("Selecione uma ou mais temporadas antes.");
      }

      if (!Object.keys(selectedAuthor).length) {
        throw new Error("Selecione um tema antes.");
      }

      if (!Object.keys(selectedCategory).length) {
        throw new Error("Selecione uma categoria antes.");
      }

      if (isHighlighted && !bannerPreview) {
        throw new Error("Informe uma imagem de banner destaque para a série.");
      }

      const createdCourse = await createCourseService({
        name: name,
        description: description.trim().length ? description : undefined,
        courses: selectedCourses.map((content, index) => ({
          course_id: content.course_id,
          position: index + 1,
        })),
        authors: [{ author_id: selectedAuthor.value }],
        categories: [{ category_id: selectedCategory.value }],
        info: {
          is_highlighted: isHighlighted,
        },
      });

      if (thumbnailFileToUpload) {
        try {
          await changeCourseThumbnail(
            thumbnailFileToUpload,
            createdCourse.trail_id
          );
        } catch (error) {
          await deleteCourse(createdCourse.trail_id);

          throw new Error(
            "Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      if (bannerFileToUpload) {
        try {
          await changeSerieBanner(bannerFileToUpload, createdCourse.trail_id);
        } catch (error) {
          await deleteCourse(createdCourse.trail_id);

          throw new Error(
            "Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Curso criado com sucesso!",
        icon: "success",
      });

      goToCourses();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao criar o curso. " + error.message,
        icon: "error",
      });
    }
  };

  const updateCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error("Informe um nome valido para o curso.");
      }

      if (!selectedCourses || !selectedCourses.length) {
        throw new Error("Selecione uma ou mais temporadas antes.");
      }

      if (!selectedAuthor) {
        throw new Error("Selecione um tema antes.");
      }

      if (!selectedCategory) {
        throw new Error("Selecione uma categoria antes.");
      }

      if (isHighlighted && !bannerPreview) {
        throw new Error(
          "Informe uma imagem de banner destaque para o conteúdo."
        );
      }

      await updateCourseService(serieId, {
        name: name,
        description: description || undefined,
        courses: selectedCourses.map((content, index) => ({
          course_id: content.course_id,
          position: index + 1,
        })),
        authors: [{ author_id: selectedAuthor.value }],
        categories: [{ category_id: selectedCategory.value }],
        info: {
          is_highlighted: isHighlighted,
          ...(isHighlighted && banner && { banner }),
        },
      });

      if (thumbnailFileToUpload) {
        try {
          await changeCourseThumbnail(thumbnailFileToUpload, serieId);
        } catch (error) {
          throw new Error(
            "Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      if (isHighlighted && bannerFileToUpload) {
        try {
          await changeSerieBanner(bannerFileToUpload, serieId);
        } catch (error) {
          throw new Error(
            "Erro ao fazer upload da imagem do banner. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Curso editado com sucesso!",
        icon: "success",
      });

      goToCourses();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao editar a curso. " + error.message,
        icon: "error",
      });
    }
  };

  const changeCourseThumbnail = async (
    localThumbnailFileToUpload: File,
    localSerieId: string
  ) => {
    const formData = new FormData();
    formData.append("file", localThumbnailFileToUpload);
    formData.append(
      "name",
      `course_thumbnail_${localSerieId}.${localThumbnailFileToUpload.type}`
    );
    formData.append("description", `thumbnail do curso de id ${localSerieId}`);

    await changeCourseThumbnailService(localSerieId, formData);
  };

  const changeSerieBanner = async (
    localBannerFileToUpload: File,
    localSerieId: string
  ) => {
    const formData = new FormData();
    formData.append("file", localBannerFileToUpload);
    formData.append(
      "name",
      `content_thumbnail_${localSerieId}.${localBannerFileToUpload.type}`
    );
    formData.append(
      "description",
      `thumbnail do conteúdo de id ${localSerieId}`
    );

    await changeTrailBanner(localSerieId, formData);
  };

  const selectCourses = () => {
    showModal(
      "Selecionar",
      <SelectCourse
        onApplySelection={applyCoursesSelection}
        selectedCourses={[...selectedCourses]}
      />
    );
  };

  const selectThumbnail = () => {
    showModal(
      "Selecionar Imagem de Capa",
      <CutImage aspect={1.812} onCutImage={onCutImage} />
    );
  };

  const selectBanner = () => {
    showModal(
      "Selecionar Imagem do Destaque",
      <CutImage aspect={1.812} onCutImage={onCutBanner} />
    );
  };

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  const onCutBanner = (file: File) => {
    if (file) {
      setBannerFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setBannerPreview(`${reader.result}`);

      hideModal();
    }
  };

  const applyCoursesSelection = (localSelectedCourses: Course[]) => {
    hideModal();

    if (localSelectedCourses) {
      setSelectedCourses(localSelectedCourses);
    }
  };

  const reorderSelectedCourses = (lesson: Course, up: boolean) => {
    if (selectedCourses) {
      const localSelectedCourses = [...selectedCourses];
      const indexOfLesson = localSelectedCourses.indexOf(lesson);
      if (indexOfLesson > -1 && indexOfLesson < localSelectedCourses.length) {
        localSelectedCourses.splice(indexOfLesson, 1);
        if (up) {
          localSelectedCourses.splice(indexOfLesson - 1, 0, lesson);
        } else {
          localSelectedCourses.splice(indexOfLesson + 1, 0, lesson);
        }
      }

      setSelectedCourses(localSelectedCourses);
    }
  };

  const removeSelectedCourse = (courseToBeRemoved: any) => {
    const courses = [...selectedCourses];
    const filteredList = courses.filter(
      (course) => course.course_id !== courseToBeRemoved.course_id
    );
    const reorderedList = filteredList.map((course, index) => ({
      ...course,
      position: index + 1,
    }));
    setSelectedCourses(reorderedList);
  };

  const getCourse = useCallback(async () => {
    const foundAuthors = await getAllAuthors();
    const foundCategories = await getAllCategories();
    if (foundAuthors) {
      setAuthors(foundAuthors);
    }
    if (foundCategories) {
      setCategories(foundCategories);
    }
    if (serieId) {
      const course = await getCourseService(serieId);

      if (course && Object.keys(course).length) {
        setName(course.trail.name);
        setDescription(course.trail.description);
        setSelectedCourses(
          course.trail.courses.sort((a: any, b: any) => a.position - b.position)
        );
        setThumbnail(course.trail.thumbnail);
        setIsHighlighted(course.trail.info.is_highlighted);
        setBanner(course.trail.info.banner);
        setBannerPreview(course.trail.info.banner);
        setSelectedCategory({
          label: course.trail.category.title,
          value: course.trail.category.id,
        });
        setSelectedAuthor({
          label: course.trail.author.name,
          value: course.trail.author.id,
        });
      }
    }
  }, [serieId]);

  const authorsToBeSelected = useMemo(() => {
    if (authors && authors.length) {
      return authors.map((author) => ({
        label: author.name,
        value: author.author_id,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [authors]);

  const categoriesToBeSelected = useMemo(() => {
    if (categories && categories.length) {
      return categories.map((category) => ({
        label: category.title,
        value: category.category_id,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [categories]);

  const goToCourses = () => {
    history.push("/trails/series");
  };

  useEffect(() => {
    getCourse();
  }, [getCourse]);

  const isEditting = useMemo(() => {
    if (serieId) {
      return true;
    }

    return false;
  }, [serieId]);

  return (
    <CreateAndEditSerieContainer>
      <BreadCrumb
        crumbs={[
          <Link to='/profile'>Perfil</Link>,
          <Link to='/trails/series'>Séries</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Série</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Série
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className='required' htmlFor='title'>
            Titúlo
          </label>
          <DefaultInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id='title'
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor='description'>Descrição</label>
          <DefaultTextArea
            value={description || ""}
            onChange={(e) => setDescription(e.target.value)}
            id='description'
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className='required' htmlFor='reference'>
            Temporadas
          </label>

          <LessonSelectionContainer>
            {selectedCourses && selectedCourses.length ? (
              <SortLessonsContainer>
                {selectedCourses.map((course, index) => (
                  <div key={course.course_id} className='selected-lessons'>
                    <div className='buttons'>
                      {index > 0 ? (
                        <DefaultButton
                          type='button'
                          title='Subir Aula'
                          className='small white up'
                          onClick={() => reorderSelectedCourses(course, true)}
                        >
                          <AiOutlineUp />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                      {index < selectedCourses.length - 1 ? (
                        <DefaultButton
                          type='button'
                          title='Descer Aula'
                          className='small white down'
                          onClick={() => reorderSelectedCourses(course, false)}
                        >
                          <AiOutlineDown />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className='lesson-title'>
                      {index + 1} - {course.title}
                    </div>
                    <div className='buttons'>
                      <DetachButton
                        onClick={() => removeSelectedCourse(course)}
                      />
                    </div>
                  </div>
                ))}
              </SortLessonsContainer>
            ) : (
              <></>
            )}
            <DefaultButton type='button' onClick={selectCourses}>
              Selecionar
            </DefaultButton>
          </LessonSelectionContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor='reference'>Capa</label>

          <ThumbnailUploadContainer>
            <DefaultButton type='button' onClick={selectThumbnail}>
              Selecionar Imagem de Capa
            </DefaultButton>

            {thumbnail && <CourseThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className='required' htmlFor='tags'>
            Tema
          </label>
          <Select
            styles={{
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
                width: "100%",
              }),
            }}
            options={authorsToBeSelected}
            value={selectedAuthor}
            onChange={(options) => {
              options &&
                setSelectedAuthor({
                  label: options.label,
                  value: options.value,
                });
            }}
            id='authors'
            placeholder='Selecione um tema para esta série.'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label className='required' htmlFor='tags'>
            Categoria
          </label>
          <Select
            styles={{
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
                width: "100%",
              }),
            }}
            options={categoriesToBeSelected}
            value={selectedCategory}
            onChange={(options) => {
              options &&
                setSelectedCategory({
                  label: options.label,
                  value: options.value,
                });
            }}
            id='categories'
            placeholder='Selecione uma categoria para este conteúdo.'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label>Destaque</label>
          <Switch>
            <input
              type='checkbox'
              checked={isHighlighted}
              onChange={(e) => setIsHighlighted(!isHighlighted)}
            />
            <span className='slider round'></span>
          </Switch>
        </DefaultCreationFormGroup>

        {isHighlighted && (
          <DefaultCreationFormGroup>
            <label className='required' htmlFor='reference'>
              Banner
            </label>

            <ThumbnailUploadContainer>
              <DefaultButton type='button' onClick={selectBanner}>
                Selecionar Imagem do Banner
              </DefaultButton>

              {bannerPreview && <CourseThumbnail src={bannerPreview} />}
            </ThumbnailUploadContainer>
          </DefaultCreationFormGroup>
        )}

        <DefaultCreationFormButtonGroup>
          <DefaultButton type='button' className='danger' onClick={goToCourses}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateCourse(e) : createCourse(e))}
            className='success'
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditSerieContainer>
  );
};

export default CreateAndEditSerie;
