import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";
import DefaultButton from "../../components/DefaultButton";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import durationToText from "../../helpers/durationToText";
import convertSecondsToTimeText from "../../helpers/seconds-to-time-text";
import Content from "../../models/content";
import {
  deleteContent,
  getAllContents,
  activateOrInactivateContent as activateOrInactivateContentService,
} from "../../services/contents";
import { ContentsContainer } from "./style";

const Contents: React.FC = () => {
  const history = useHistory();

  const [contents, setContents] = useState([] as Content[]);

  const getContents = async () => {
    const localContents = await getAllContents();
    if (localContents && localContents.length) {
      setContents(localContents);
    }
  };

  const editContent = useCallback(
    (contentId: string) => {
      history.push(`edit-content/${contentId}`);
    },
    [history]
  );

  const removeContent = useCallback(async (contentId: string) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover este curso?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteContent(contentId);

          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Curso excluido com sucesso!",
          });

          await getContents();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: `Erro ao excluir curso. ${error.response && error.response.status === 423
              ? "Este curso já está associado a uma aula!"
              : error.message
              }`,
          });
        }
      }
    });
  }, []);

  const createContent = async () => {
    history.push("create-content");
  };

  const activateOrInactivateContent = useCallback(
    async (contentId: string, activate: boolean) => {
      Swal.fire({
        title: "Confirmação",
        text: `Tem certeza que deseja ${activate ? "ativar" : "inativar"
          } este curso?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateContentService(contentId, activate);

            getContents();
          } catch (error) {
            Swal.fire({
              title: "Erro",
              text: `Erro ao ${activate ? "ativar" : "inativar"} conteúdo. ${error.response && error.response.status === 423
                ? "Erro ao excluir curso!"
                : error.message
                }`,
            });
          }
        }
      });
    },
    []
  );

  const contentsToBeShown = useMemo(() => {
    return contents && contents.length
      ? contents.map((content) => ({
        title: content.title,
        description: content.description,
        duration: convertSecondsToTimeText(content.duration),
        durationMask: durationToText(content.duration),
        active: content.active ? <AiOutlineCheck /> : <AiOutlineClose />,
        actions: (
          <>
            <DefaultButton
              onClick={() => editContent(content.id)}
              className="small info"
              title="Editar Curso"
            >
              <BiEdit />
            </DefaultButton>{" "}
            <DefaultButton
              onClick={() =>
                activateOrInactivateContent(content.id, !content.active)
              }
              className="small warning"
              title={(content.active ? "Inativar" : "Ativar") + " curso"}
            >
              {content.active ? <AiOutlineClose /> : <AiOutlineCheck />}
            </DefaultButton>{" "}
            <DefaultButton
              onClick={() => {
                removeContent(content.id);
              }}
              className="small danger"
              title="Excluir Curso"
            >
              <BiTrash />
            </DefaultButton>
          </>
        ),
      }))
      : [];
  }, [contents, editContent, removeContent, activateOrInactivateContent]);

  useEffect(() => {
    getContents();
  }, []);

  return (
    <ContentsContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Curso</span>]}
      />

      <DefaultPageTitle>Curso</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createContent}>Criar Curso</DefaultButton>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: "title",
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: "description",
          },
          {
            headerLabel: <span>Duração</span>,
            propName: "duration",
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: "active",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={"Não foram encontrados cursos cadastrados!"}
      />
    </ContentsContainer>
  );
};

export default Contents;
