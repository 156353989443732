import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";
import DefaultButton from "../../components/DefaultButton";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import convertSecondsToTimeText from "../../helpers/seconds-to-time-text";
import {default as ILesson} from "../../models/lesson";
import {
  deleteLesson,
  getAllLessons,
  activateOrInactivateLesson as activateOrInactivateLessonService,
} from "../../services/lessons";
import { ContentsContainer } from "./style";

const Lessons: React.FC = () => {
  const history = useHistory();

  const [lessons, setLessons] = useState([] as ILesson[]);

  const getContents = async () => {
    const localContents = await getAllLessons();
    if (localContents && localContents.length) {
      setLessons(localContents);
    }
  };

  const editContent = useCallback(
    (contentId: string) => {
      history.push(`edit-content/${contentId}`);
    },
    [history]
  );

  const removeContent = useCallback(async (contentId: string) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover este episódio?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteLesson(contentId);

          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Episódio excluido com sucesso!",
          });

          await getContents();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: `Erro ao excluir conteúdo. ${error.response && error.response.status === 423
              ? "Este vídeo já está associado a uma temporada!"
              : error.message
              }`,
          });
        }
      }
    });
  }, []);

  const createContent = async () => {
    history.push("create-content");
  };

  const activateOrInactivateContent = useCallback(
    async (contentId: string, activate: boolean) => {
      Swal.fire({
        title: "Confirmação",
        text: `Tem certeza que deseja ${activate ? "ativar" : "inativar"
          } este episódio?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateLessonService(contentId, activate);

            getContents();
          } catch (error) {
            Swal.fire({
              title: "Erro",
              text: `Erro ao ${activate ? "ativar" : "inativar"} episódio. ${error.response && error.response.status === 423
                ? "Este vídeo já está associado a uma temporada!"
                : error.message
                }`,
            });
          }
        }
      });
    },
    []
  );

  const LessonsToBeShown = useMemo(() => {
    return lessons && lessons.length
      ? lessons.map((lesson) => ({
        title: lesson.title,
        description: lesson.description,
        duration: convertSecondsToTimeText(parseInt(lesson.duration)),
        active: lesson.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
        actions: (
          <>
            <DefaultButton
              onClick={() => editContent(lesson.content_id)}
              className="small info"
              title="Editar Episódio"
            >
              <BiEdit />
            </DefaultButton>{" "}
            <DefaultButton
              onClick={() =>
                activateOrInactivateContent(lesson.content_id, !lesson.is_active)
              }
              className="small warning"
              title={(lesson.is_active ? "Inativar" : "Ativar") + "Episódio"}
            >
              {lesson.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
            </DefaultButton>{" "}
            <DefaultButton
              onClick={() => {
                removeContent(lesson.content_id);
              }}
              className="small danger"
              title="Excluir Episódio"
            >
              <BiTrash />
            </DefaultButton>
          </>
        ),
      }))
      : [];
  }, [lessons, editContent, removeContent, activateOrInactivateContent]);

  useEffect(() => {
    getContents();
  }, []);

  return (
    <ContentsContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Episódios</span>]}
      />

      <DefaultPageTitle>Episódios</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createContent}>Criar Episódio</DefaultButton>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: "title",
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: "description",
          },
          {
            headerLabel: <span>Duração</span>,
            propName: "duration",
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: "active",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={LessonsToBeShown}
        emptyListMessage={"Não foram encontrados espisódios cadastrados!"}
      />
    </ContentsContainer>
  );
};

export default Lessons;
