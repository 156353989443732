import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import Swal from "sweetalert2";

import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import SelectLesson from "./components/SelectLesson";
import { ReactComponent as DetachButton } from "../../assets/detach.svg";

import { hideModal, showModal } from "../../helpers/modal";
import checkEmptyString from "../../helpers/check-empty-string";

import Lesson from "../../models/lesson";

import {
  getCourse as getCourseService,
  updateCourse as updateCourseService,
  createCourse as createCourseService,
} from "../../services/courses";

import {
  SortLessonsContainer,
  LessonSelectionContainer,
  CreateAndEditCourseContainer,
} from "./style";

interface CreateAndEditCourseProps {
  courseId: string;
}

const CreateAndEditCourse: React.FC = () => {
  const history = useHistory();
  const { courseId } = useParams<CreateAndEditCourseProps>();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedLessons, setSelectedLessons] = useState([] as Lesson[]);

  const createCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error("Informe um título valido para a temporada.");
      }

      if (!selectedLessons || !selectedLessons.length) {
        throw new Error("Selecione uma ou mais episódios antes.");
      }

      await createCourseService({
        title: name,
        description: description.trim().length ? description : undefined,
        contents: selectedLessons.map((lesson, index) => ({
          content_id: lesson.content_id,
          position: index + 1,
        })),
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Temporada criada com sucesso!",
        icon: "success",
      });

      goToCourses();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao criar a temporada. " + error.message,
        icon: "error",
      });
    }
  };

  const updateCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error("Informe um nome valido para a temporada.");
      }

      if (!selectedLessons || !selectedLessons.length) {
        throw new Error("Selecione uma ou mais episódios antes.");
      }

      await updateCourseService(courseId, {
        title: name,
        description: description || undefined,
        contents: selectedLessons.map((lesson, index) => ({
          content_id: lesson.content_id,
          position: index + 1,
        })),
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Temporada editada com sucesso!",
        icon: "success",
      });

      goToCourses();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao editar a temporada. " + error.message,
        icon: "error",
      });
    }
  };

  const selectLessons = () => {
    showModal(
      "Selecionar",
      <SelectLesson
        onApplySelection={applyLessonsSelection}
        selectedLessons={[...selectedLessons]}
      />
    );
  };

  const applyLessonsSelection = (localSelectedLessons: Lesson[]) => {
    hideModal();

    if (localSelectedLessons) {
      setSelectedLessons(localSelectedLessons);
    }
  };

  const reorderSelectedLessons = (lesson: Lesson, up: boolean) => {
    if (selectedLessons) {
      const localSelectedLessons = [...selectedLessons];
      const indexOfLesson = localSelectedLessons.indexOf(lesson);
      if (indexOfLesson > -1 && indexOfLesson < localSelectedLessons.length) {
        localSelectedLessons.splice(indexOfLesson, 1);
        if (up) {
          localSelectedLessons.splice(indexOfLesson - 1, 0, lesson);
        } else {
          localSelectedLessons.splice(indexOfLesson + 1, 0, lesson);
        }
      }

      setSelectedLessons(localSelectedLessons);
    }
  };

  const removeSelectedLesson = (lessonToBeRemoved: Lesson) => {
    const lessons = [...selectedLessons];
    const filteredList = lessons.filter(
      (lesson) => lesson.content_id !== lessonToBeRemoved.content_id
    );
    const reorderedList = filteredList.map((lesson, index) => ({
      ...lesson,
      position: index + 1,
    }));

    setSelectedLessons(reorderedList);
  };

  const getCourse = useCallback(async () => {
    if (courseId) {
      const course = await getCourseService(courseId);
      if (course && Object.keys(course).length) {
        setName(course.course.title);
        setDescription(course.course.description);
        setSelectedLessons(
          course.course.contents.sort((a: any, b: any) =>
            a.position > b.position ? 1 : -1
          )
        );
      }
    }
  }, [courseId]);

  const goToCourses = () => {
    history.push("/trails/episodes");
  };

  useEffect(() => {
    getCourse();
  }, [getCourse]);

  const isEditing = useMemo(() => {
    if (courseId) {
      return true;
    }

    return false;
  }, [courseId]);
  return (
    <CreateAndEditCourseContainer>
      <BreadCrumb
        crumbs={[
          <Link to='/profile'>Perfil</Link>,
          <Link to='/trails/episodes'>Séries</Link>,
          <span>{isEditing ? "Editar" : "Criar"} Temporadas</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditing ? "Editar" : "Criar"} Temporada
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className='required' htmlFor='title'>
            Titúlo
          </label>
          <DefaultInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id='title'
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor='description'>Descrição</label>
          <DefaultTextArea
            value={description || ""}
            onChange={(e) => setDescription(e.target.value)}
            id='description'
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className='required' htmlFor='reference'>
            Vídeos
          </label>

          <LessonSelectionContainer>
            {selectedLessons && selectedLessons.length ? (
              <SortLessonsContainer>
                {selectedLessons.map((lesson, index) => (
                  <div key={lesson.content_id} className='selected-lessons'>
                    <div className='buttons'>
                      {index > 0 ? (
                        <DefaultButton
                          type='button'
                          title='Subir Aula'
                          className='small white up'
                          onClick={() => reorderSelectedLessons(lesson, true)}
                        >
                          <AiOutlineUp />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                      {lesson.position || index < selectedLessons.length - 1 ? (
                        <DefaultButton
                          type='button'
                          title='Descer Aula'
                          className='small white down'
                          onClick={() => reorderSelectedLessons(lesson, false)}
                        >
                          <AiOutlineDown />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className='lesson-title'>
                      {index + 1} - {lesson.title}
                    </div>
                    <div className='buttons'>
                      <DetachButton
                        onClick={() => removeSelectedLesson(lesson)}
                      />
                    </div>
                  </div>
                ))}
              </SortLessonsContainer>
            ) : (
              <></>
            )}
            <DefaultButton type='button' onClick={selectLessons}>
              Selecionar
            </DefaultButton>
          </LessonSelectionContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type='button' className='danger' onClick={goToCourses}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditing ? updateCourse(e) : createCourse(e))}
            className='success'
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCourseContainer>
  );
};

export default CreateAndEditCourse;
