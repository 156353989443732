const formatName = (name: string) => {
  const nameSplit = name.split('-');

  if (nameSplit[0].length === 20) {
    nameSplit.splice(0, 1);
  }

  const finalName = nameSplit.join('-');
  return finalName;
};

export { formatName };