import {
  AiOutlineFileText,
  AiOutlineUser,
} from "react-icons/ai";
import { IoLibraryOutline } from "react-icons/io5";
import { GiPencil, GiPencilRuler } from "react-icons/gi";
import { FaHome } from "react-icons/fa";
import { BsBook, BsClipboardData, BsFileText } from "react-icons/bs";

const menus = [
  {
    label: (
      <>
        <span className="icon">
          <FaHome />
        </span>
        <span className="text">Dashboard</span>
      </>
    ),
    path: "/home",
  },
  {
    label: (
      <>
        <span className="icon">
          <AiOutlineUser />
        </span>
        <span className="text">Usuários</span>
      </>
    ),
    path: "/users",
  },
  {
    label: (
      <>
        <span className="icon">
          <BsBook />
        </span>
        <span className="text">Temas</span>
      </>
    ),
    path: "/authors",
  },
  {
    label: (
      <>
        <span className="icon">
          <IoLibraryOutline />
        </span>
        <span className="text">Categorias</span>
      </>
    ),
    path: "/categories",
  },
  {
    label: (
      <>
        <span className="icon">
          <AiOutlineFileText />
        </span>
        <span className="text">Cursos</span>
      </>
    ),
    path: "/contents",
  },
  {
    label: (
      <>
        <span className="icon">
          <GiPencilRuler />
        </span>
        <span className="text">Séries</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <GiPencil />
            </span>
            <span className="text">Episódio</span>
          </>
        ),
        path: "/trails/contents",
      },
      {
        label: (
          <>
            <span className="icon">
              <AiOutlineFileText />
            </span>
            <span className="text">Temporada</span>
          </>
        ),
        path: "/trails/episodes",
      },
      {
        label: (
          <>
            <span className="icon">
              <IoLibraryOutline />
            </span>
            <span className="text">Série</span>
          </>
        ),
        path: "/trails/series",
      },
    ]
  },
  {
    label: (
      <>
        <span className="icon">
          <IoLibraryOutline />
        </span>
        <span className="text">FAQs</span>
      </>
    ),
    path: "/faqs",
  },
  {
    label: (
      <>
        <span className="icon">
          <BsClipboardData />
        </span>
        <span className="text">Relatórios</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <BsFileText />
            </span>
            <span className="text">Acessos - Usuários</span>
          </>
        ),
        path: '/reports/acessos-usuarios',
      },
      {
        label: (
          <>
            <span className="icon">
              <BsFileText />
            </span>
            <span className="text">Séries assistidas</span>
          </>
        ),
        path: '/reports/trilhas-assistidas',
      },
      {
        label: (
          <>
            <span className="icon">
              <BsFileText />
            </span>
            <span className="text">Cursos assistidos</span>
          </>
        ),
        path: '/reports/cursos-assistidos',
      },
    ],
  },
];

export default menus;
