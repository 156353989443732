import React, { useCallback, useEffect, useMemo, useState } from "react";
import DefaultButton from "../../../../components/DefaultButton";
import DefaultTable from "../../../../components/DefaultTable";
import Pagination from "../../../../components/Pagination";
import Course from "../../../../models/course";
import { getAllCourses } from "../../../../services/courses";
import { SelectCourseContainer } from "./style";

interface SelectCourseProps {
  selectedCourses: Course[];
  onApplySelection: (selectedCourses: Course[]) => void;
}

const SelectCourse: React.FC<SelectCourseProps> = ({
  selectedCourses,
  onApplySelection,
}) => {
  const [allCourses, setAllCourses] = useState([] as Course[]);
  const [checkedCourses, setCheckedCourses] = useState(selectedCourses);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const coursesPerPage = 10;
  const filter = { most_recent: true };

  const getCourses = async () => {
    const localCourses = await getAllCourses(filter);
    if (localCourses && localCourses.length) {
      setTotalPages(Math.ceil(localCourses.length / coursesPerPage))
    }
  };

  const handleCheckCourse = useCallback(
    (event: React.FormEvent<HTMLInputElement>, course: Course) => {
      if (event.currentTarget.checked) {
        setCheckedCourses([...checkedCourses, course]);
      } else {
        
        const filteredCourses = checkedCourses.filter(selectedCourse => selectedCourse.course_id !== course.course_id)
        setCheckedCourses(filteredCourses);
      }
    },
    [checkedCourses]
  );

  const applySelection = (event: React.FormEvent<HTMLButtonElement>) => {
    onApplySelection(checkedCourses);
  };

  const lessonsToBeShown = useMemo(() => {
    return allCourses.map((course) => {
      const shouldBeChecked = checkedCourses
        .map((checkedCourse) => checkedCourse.course_id)
        .includes(course.course_id);

      return {
        title: course.title,
        description: course.description,
        check: (
          <>
            <input
              type="checkbox"
              value={course.course_id}
              defaultChecked={shouldBeChecked}
              onChange={(e) => handleCheckCourse(e, course)}
            />
          </>
        ),
      };
    });
  }, [allCourses, checkedCourses, handleCheckCourse]);

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    (async () => {
      setAllCourses([]);
      
      const currentCourses = await getAllCourses({
        ...filter,
        limit: coursesPerPage,
        offset: page > 1 ? (page - 1) * coursesPerPage : undefined,

      });
      
      if (currentCourses && currentCourses.length) {
        setAllCourses(currentCourses);
      }
    })();
  }, [page]);

  return (
    <SelectCourseContainer>
      <DefaultTable
        headersConfig={[
          { headerLabel: <span>Título</span>, propName: "title" },
          { headerLabel: <span>Descrição</span>, propName: "description" },
          { headerLabel: <span>Selecionar</span>, propName: "check" },
        ]}
        items={lessonsToBeShown}
      />
      {totalPages > 1 && (
        <Pagination totalPages={totalPages} setPage={setPage} />
      )}
      <DefaultButton onClick={applySelection}>Aplicar Seleção</DefaultButton>
    </SelectCourseContainer>
  );
};

export default SelectCourse;