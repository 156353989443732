import styled from 'styled-components';

const CreateButtonDefaultContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
`;

export {
  CreateButtonDefaultContainer
}