import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultFilterForm from "../../components/DefaultFilterForm";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { EngagementContainer } from "./style";
import Select from "react-select";
import DefaultButton from "../../components/DefaultButton";
import { generateTrailUserReport } from "../../services/reports";
import { getAllCourses } from "../../services/trails";
import Trail from "../../models/trail";
import { getAllUsers } from "../../services/users";
import DefaultInput from "../../components/DefaultInput";


const MONTHS = [
  { value: "01", label: "Janeiro" },
  { value: "02", label: "Fevereiro" },
  { value: "03", label: "Março" },
  { value: "04", label: "Abril" },
  { value: "05", label: "Maio" },
  { value: "06", label: "Junho" },
  { value: "07", label: "Julho" },
  { value: "08", label: "Agosto" },
  { value: "09", label: "Setembro" },
  { value: "10", label: "Outubro" },
  { value: "11", label: "Novembro" },
  { value: "12", label: "Dezembro" },
]

const ReportTrilhaUsuario: React.FC = () => {
  const [trails, setTrails] = useState(
    [] as { value: string; label: string }[]
  );
  const [users, setUsers] = useState(
    [] as { value: string; label: string }[]
  );

  const [selectedTrail, setSelectedTrail] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [startTrailBegin, setStartTrailBegin] = useState("");
  const [startTrailEnd, setStartTrailEnd] = useState("");
  const [finishTrailBegin, setFinishTrailBegin] = useState("");
  const [finishTrailEnd, setFinishTrailEnd] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState("01");

  const generateReport = async (reportType: string) => {

    const queryStrings: any = {
      trail_id: selectedTrail,
      user_id: selectedUser,
      start_at_begin: startTrailBegin,
      start_at_end: startTrailEnd,
      finish_at_begin: finishTrailBegin,
      finish_at_end: finishTrailEnd,
    };

    for (let prop of Object.keys(queryStrings)) {
      if (!queryStrings[prop]) {
        delete queryStrings[prop];
      }
    }

    await generateTrailUserReport(reportType, queryStrings);
  };

  useEffect(() => {
    (async () => {
      const localTrails = await getAllCourses();
      if (localTrails && localTrails.length) {
        setTrails(localTrails.map((t: Trail) => (
          { label: t.name, value: t.trail_id })));
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const localUsers = await getAllUsers({});
      if (localUsers && localUsers.users.length) {
        setUsers(localUsers.users.map((u) => (
          { label: u.name, value: u.user_id })));
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const montLastDay = new Date(year, parseInt(month), 0).getDate();
      const monthFirstDay = '01';

      setStartTrailBegin(`${year}-${month}-${monthFirstDay}`);
      setStartTrailEnd(`${year}-${month}-${montLastDay}`);
      setFinishTrailBegin(`${year}-${month}-${monthFirstDay}`);
      setFinishTrailEnd(`${year}-${month}-${montLastDay}`);

    })();
  }, [year, month]);

  return (
    <EngagementContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/home">Home</Link>,
          <span>Relatórios</span>,
          <span>Séries Assistidas</span>,
        ]}
      />

      <DefaultPageTitle>Acessos aos conteúdos das séries visualizadas</DefaultPageTitle>

      <DefaultFilterForm>
        <span className="entire-row">
          <label htmlFor="trilhas">Séries</label>
          <Select
            options={trails}
            onChange={(option) => option && setSelectedTrail(option.value)}
          />
        </span>
        <span className="entire-row">
          <label htmlFor="usuarios">Usuários</label>
          <Select
            options={users}
            onChange={(option) => option && setSelectedUser(option.value)}
          />
        </span>

        <span className="entire-row">
          <label htmlFor="startAt">Escolha o mês</label>
          <select onChange={(e) => setMonth(e.target.value)}>
            {MONTHS.map((m) => (
              <option value={m.value}>{m.label}</option>
            ))}
          </select>
        </span>

        <span className="entire-row">
          <label className="required" htmlFor="startAt">Digite o ano</label>
          <DefaultInput
            id="title"
            type="text"
            required
            value={year}
            onChange={(e) => setYear(Number(e.target.value))}
          />
        </span>

        <span className="entire-row center">
          <DefaultButton
            onClick={() => generateReport("pdf")}
            type="button"
          >
            Gerar Relatório (PDF)
          </DefaultButton>{" "}
          <DefaultButton
            onClick={() => generateReport("csv")}
            type="button"
          >
            Gerar Relatório (CSV)
          </DefaultButton>
        </span>
      </DefaultFilterForm>
    </EngagementContainer >
  );
};

export default ReportTrilhaUsuario;
