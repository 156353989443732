import styled from "styled-components";

const AppContainer = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AppLeftContent = styled.div`
  height: 100%;
  width: 250px;
  box-shadow: 0 0 2rem rgba(136, 152, 170, 0.15);
`;

const AppRightContent = styled.div`
  height: 100%;
  width: 85%;
  overflow-y: auto;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

const AppContent = styled.div`
  padding: 20px 30px;
  width: 100%;
  flex-grow: 1;
`;

export { AppContainer, AppLeftContent, AppRightContent, AppContent };
