import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";
import DefaultButton from "../../components/DefaultButton";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import convertSecondsToTimeText from "../../helpers/seconds-to-time-text";
import Content from "../../models/content";
import {
  deleteContent,
  getAllContents,
} from "../../services/contents";

import { activateOrInactivateCategory as activateOrInactivateCategoryService, getAllCategories, deleteCategory } from "../../services/categories";
import { CategoriesContainer } from "./style";
import Category from "../../models/category";

const Categories: React.FC = () => {
  const history = useHistory();

  const [categories, setCategories] = useState([] as Category[]);

  const getCategories = async () => {
    const localCategories = await getAllCategories();
    if (localCategories && localCategories.length) {
      setCategories(localCategories);
    }
  };

  const editCategory = useCallback(
    (categoryId: any) => {
      history.push(`edit-category/${categoryId}`);
    },
    [history]
  );

  const removeCategory = useCallback(async (categoryId: any) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover esta categoria?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCategory(categoryId);

          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Categoria excluida com sucesso!",
          });

          await getCategories();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: `Erro ao excluir categoria. ${error.response && error.response.status === 423
              ? "Esta categoria já está associada a um curso!"
              : error.message
              }`,
          });
        }
      }
    });
  }, []);

  const createCategory = async () => {
    history.push("create-category");
  };

  const activateOrInactivateCategory = useCallback(
    async (categoryId: any, activate: boolean) => {
      Swal.fire({
        title: "Confirmação",
        text: `Tem certeza que deseja ${activate ? "ativar" : "inativar"
          } esta categoria?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateCategoryService(categoryId, activate);

            getCategories();
          } catch (error) {
            Swal.fire({
              title: "Erro",
              text: `Erro ao ${activate ? "ativar" : "inativar"} categoria. ${error.response && error.response.status === 423
                ? "Esta categoria já está associado a um curso!"
                : error.message
                }`,
            });
          }
        }
      });
    },
    []
  );

  const contentsToBeShown = useMemo(() => {
    return categories && categories.length
      ? categories.map((category) => ({
        id: category.category_id,
        title: category.title,
        description: category.description,
        active: category.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
        actions: (
          <>
            <DefaultButton
              onClick={() => editCategory(category.category_id)}
              className="small info"
              title="Editar Categoria"
            >
              <BiEdit />
            </DefaultButton>{" "}
            <DefaultButton
              onClick={() =>
                activateOrInactivateCategory(category?.category_id, !category.is_active)
              }
              className="small warning"
              title={(category.is_active ? "Inativar" : "Ativar") + " Conteúdo"}
            >

              {category.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
            </DefaultButton>{" "}
            <DefaultButton
              onClick={() => {
                removeCategory(category.category_id);
              }}
              className="small danger"
              title="Excluir Categoria"
            >
              <BiTrash />
            </DefaultButton>
          </>
        ),
      }))
      : [];
  }, [categories, editCategory, removeCategory, activateOrInactivateCategory]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <CategoriesContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Categorias</span>]}
      />

      <DefaultPageTitle>Categorias</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createCategory}>Criar Categoria</DefaultButton>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: "title",
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: "description",
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: "active",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={"Não foram encontrados categorias cadastradas!"}
      />
    </CategoriesContainer>
  );
};

export default Categories;
