import  { UserGetAllResponse } from "../models/from-api-response/user";
import { getActualUser as getActualUserService } from "../services/users";

const getActualUser = async (): Promise<UserGetAllResponse> => {
  let user = {} as UserGetAllResponse;
  if (!window.localStorage.getItem("actual-alpargatas-user")) {
    user = await getActualUserService();
    window.localStorage.setItem("actual-alpargatas-user", JSON.stringify(user));
  } else {
    user = JSON.parse(window.localStorage.getItem("actual-alpargatas-user") || "");
  }

  return user;
};

export default getActualUser;
