import convertToUser from "../helpers/converters/convert-user";
import httpClient from "../http-client";
import {GetAllUsersResponse, GetCountUsersResponse} from "../models/from-api-response/user";

interface Filters {
  limit?: Number;
  offset?: Number;
  search?: string;
}

const getAllUsers = async (filters: Filters) => {
  const userResponse = (await httpClient.get<GetAllUsersResponse>("/users", {params: {...filters}})).data;

  if (userResponse && userResponse.users.length) {
    userResponse.users = userResponse.users.map(convertToUser);
  }
  return userResponse;
};

const getCountUsers = async () => {
  const response = (await httpClient.get<GetCountUsersResponse>("/users/count")).data;
  return response;
};

const getActualUser = async () => {
  return (await httpClient.get("users/me")).data;
};

const updateUserRole = async (userId : string, roles: string[]) => {
  return (await httpClient.put("users/" + userId, {roles})).data;
};

export { getAllUsers, getActualUser, updateUserRole, getCountUsers };
