import React from "react";
import {
  DefaultTableContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "./style";

interface HeaderConfig {
  propName: string;
  headerLabel: React.ReactElement;
  noWrap?: boolean;
}

interface DefatultTableProps {
  headersConfig: HeaderConfig[];
  items: any[];
  emptyListMessage?: string;
}

const DefaultTable: React.FC<DefatultTableProps> = ({
  headersConfig,
  items,
  emptyListMessage,
}) => {
  return (
    <DefaultTableContainer>
      <Table>
        <Thead>
          <Tr>
            {headersConfig && headersConfig.length ? (
              headersConfig.map((headerConfig, index) => (
                <Th key={index}>{headerConfig.headerLabel}</Th>
              ))
            ) : (
              <></>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {items && items.length ? (
            items.map((item, index) => (
              <Tr key={index}>
                {item &&
                Object.keys(item).length &&
                headersConfig &&
                headersConfig.length ? (
                  headersConfig.map((headerConfig, index) => (
                    <Td
                      key={index}
                      style={{
                        whiteSpace: headerConfig.noWrap ? "nowrap" : "normal",
                      }}
                    >
                      {(() => {
                        switch (typeof item[headerConfig.propName]) {
                          case "boolean":
                            return `${item[headerConfig.propName]}`;
                          default:
                            return item[headerConfig.propName];
                        }
                      })()}
                    </Td>
                  ))
                ) : (
                  <></>
                )}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td
                style={{ textAlign: "center" }}
                colSpan={headersConfig.length}
              >
                {emptyListMessage || "Não foram fornecidos itens para a lista"}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </DefaultTableContainer>
  );
};

export default DefaultTable;
