import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

httpClient.interceptors.request.use(function (config) {
  const loading = document.getElementById("loading");
  if (loading && !config.url?.includes("files/url-signed")) {
    loading.style.display = "flex";
  }

  const token = window.localStorage.getItem("alpargatas-api-token");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

httpClient.interceptors.response.use(
  function (response) {
    const loading = document.getElementById("loading");
    if (loading) {
      if (response.config.method !== "GET") {
        loading.style.display = "none";
      } else {
        setTimeout(() => {
          loading.style.display = "none";
        }, 300);
      }
    }

    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = `${process.env.REACT_APP_REDIRECT_LOGIN}`;
    } else {
      setTimeout(() => {
        const loading = document.getElementById("loading");
        if (loading) {
          loading.style.display = "none";
        }
      }, 300);

      return Promise.reject(error);
    }
  }
);

export default httpClient;
